/**
 * 共通
 */
// ログインユーザ情報取得
export const USER_AUTHINFOS =
  'https://mynhy0rw14.execute-api.ap-northeast-1.amazonaws.com/prod/userauthinfos/';
// ヘッダー項目生成
export const HEADER_LIST =
  'https://esuhxnvn1f.execute-api.ap-northeast-1.amazonaws.com/prod/headerlist/';
// バリデーションチェック
export const API_URL_VALIDATION_RESULT =
  'https://mynhy0rw14.execute-api.ap-northeast-1.amazonaws.com/prod/validationresult/';
// バリデーションチェック(複数)
export const API_URL_MULTIVALIDATION_RESULT =
  'https://mynhy0rw14.execute-api.ap-northeast-1.amazonaws.com/prod/multivalidationresult/';
// 画像登録
export const API_URL_UPLOAD_IMAGE =
  'https://mynhy0rw14.execute-api.ap-northeast-1.amazonaws.com/prod/uploadimage/';
// ブロック実行必要回数の取得
export const API_URL_RUN_COUNT =
  'https://mynhy0rw14.execute-api.ap-northeast-1.amazonaws.com/prod/runcount/';

/**
 * TOP画面
 */
/** お知らせ */
// お知らせ確認
export const API_URL_INFORMATIONS2 =
  'https://yvgvz5m58e.execute-api.ap-northeast-1.amazonaws.com/prod/informations2';

/**
 * マスタ画面
 */
/** 販促資材マスタ */
// REST API(検索/登録/削除)
export const API_URL_ITEM =
  'https://esuhxnvn1f.execute-api.ap-northeast-1.amazonaws.com/prod/items/';
// 販促資材マスタ.入力画面
export const API_URL_INPUT_ITEM =
  'https://esuhxnvn1f.execute-api.ap-northeast-1.amazonaws.com/prod/inputitems/';
// 販促資材マスタ.検索画面
export const API_URL_SEARCH_ITEM =
  'https://esuhxnvn1f.execute-api.ap-northeast-1.amazonaws.com/prod/searchitems/';
// 販促資材マスタ.入庫登録、在庫調整
export const API_URL_STOCK =
  'https://ltqkvpvfvl.execute-api.ap-northeast-1.amazonaws.com/prod/stock/';

/** 届先マスタ */
// REST API(検索/登録/削除)
export const API_URL_DELIVERY =
  'https://esuhxnvn1f.execute-api.ap-northeast-1.amazonaws.com/prod/deliveries/';
// 届先マスタ.入力画面
export const API_URL_INPUT_DELIVERY =
  'https://esuhxnvn1f.execute-api.ap-northeast-1.amazonaws.com/prod/inputdeliveries/';
// 届先マスタ.検索画面
export const API_URL_SEARCH_DELIVERY =
  'https://esuhxnvn1f.execute-api.ap-northeast-1.amazonaws.com/prod/searchdeliveries/';

/** 営業日マスタ */
// REST API(検索/登録/削除)
export const API_URL_BIZDAY =
  'https://esuhxnvn1f.execute-api.ap-northeast-1.amazonaws.com/prod/bizdays/';
// 営業日マスタ.入力画面
export const API_URL_INPUT_BIZDAY =
  'https://esuhxnvn1f.execute-api.ap-northeast-1.amazonaws.com/prod/inputbizdays/';
// 営業日マスタ.検索画面
export const API_URL_SEARCH_BIZDAY =
  'https://esuhxnvn1f.execute-api.ap-northeast-1.amazonaws.com/prod/searchbizdays/';

/** ユーザマスタ */
// REST API(検索/登録/削除)
export const API_URL_USER =
  'https://yvgvz5m58e.execute-api.ap-northeast-1.amazonaws.com/prod/users/';
// ユーザマスタ.入力画面
export const API_URL_INPUT_USER =
  'https://yvgvz5m58e.execute-api.ap-northeast-1.amazonaws.com/prod/inputusers/';
// ユーザマスタ.検索画面
export const API_URL_SEARCH_USER =
  'https://yvgvz5m58e.execute-api.ap-northeast-1.amazonaws.com/prod/searchusers/';

/** 組織マスタ */
// REST API(検索/登録/削除)
export const API_URL_DEPARTMENT =
  'https://yvgvz5m58e.execute-api.ap-northeast-1.amazonaws.com/prod/departments/';
// 組織マスタ.入力画面
export const API_URL_INPUT_DEPARTMENT =
  'https://yvgvz5m58e.execute-api.ap-northeast-1.amazonaws.com/prod/inputdepartments/';
// 組織マスタ.検索画面
export const API_URL_SEARCH_DEPARTMENT =
  'https://yvgvz5m58e.execute-api.ap-northeast-1.amazonaws.com/prod/searchdepartments/';

/** お知らせマスタ */
// REST API(検索/登録/削除)
export const API_URL_INFORMATION =
  'https://yvgvz5m58e.execute-api.ap-northeast-1.amazonaws.com/prod/informations/';
// お知らせマスタ.入力画面
export const API_URL_INPUT_INFORMATION =
  'https://yvgvz5m58e.execute-api.ap-northeast-1.amazonaws.com/prod/inputinformations/';
// お知らせマスタ.検索画面
export const API_URL_SEARCH_INFORMATION =
  'https://yvgvz5m58e.execute-api.ap-northeast-1.amazonaws.com/prod/searchinformations/';

/**
 * 発注管理画面
 */
/** 発注管理.販促資材 */
// REST API(検索)
export const API_URL_ORDER_ITEM =
  'https://p1kswwr00a.execute-api.ap-northeast-1.amazonaws.com/prod/orderitems/';
// 発注管理.販促資材.検索画面
export const API_URL_SEARCH_ORDER_ITEM =
  'https://p1kswwr00a.execute-api.ap-northeast-1.amazonaws.com/prod/searchorderitems/';
// REST API(登録→発注管理.販促資材/削除→発注管理.選択リスト.販促資材)
export const API_URL_WORK_ORDER_ITEM =
  'https://p1kswwr00a.execute-api.ap-northeast-1.amazonaws.com/prod/workorderitems/';

/** 発注管理.届先 */
// REST API(検索)
export const API_URL_ORDER_DELIVERIE =
  'https://p1kswwr00a.execute-api.ap-northeast-1.amazonaws.com/prod/orderdeliveries/';
// 発注管理.届先.検索画面
export const API_URL_SEARCH_ORDER_DELIVERIE =
  'https://p1kswwr00a.execute-api.ap-northeast-1.amazonaws.com/prod/searchorderdeliveries/';
// REST API(検索→発注管理.選択リスト.届先/登録→発注管理.届先/削除→発注管理.選択リスト.届先)
export const API_URL_WORK_ORDER_DELIVERIE =
  'https://p1kswwr00a.execute-api.ap-northeast-1.amazonaws.com/prod/workorderdeliveries/';

/** 発注管理.選択リスト */
// 発注管理.選択リスト.販促資材
export const API_URL_WORK_ORDER_ITEM_STOCK_INFO =
  'https://p1kswwr00a.execute-api.ap-northeast-1.amazonaws.com/prod/workitemstockinfo/';

/** 発注管理.数量 */
// REST API (検索→発注管理.数量/登録→発注管理.選択リスト/削除→発注管理.数量)
export const API_URL_WORK_ORDER =
  'https://p1kswwr00a.execute-api.ap-northeast-1.amazonaws.com/prod/workorders/';
// REST API (検索→発注管理.注文履歴/登録→発注管理.数量)
export const API_URL_ORDER =
  'https://p1kswwr00a.execute-api.ap-northeast-1.amazonaws.com/prod/orders/';
// 発注管理.数量.入力画面
export const API_URL_INPUT_WORK_ORDER =
  'https://p1kswwr00a.execute-api.ap-northeast-1.amazonaws.com/prod/inputworkorders/';

/** 発注管理.注文履歴 */
// 発注管理.注文履歴.入力画面
export const API_URL_INPUT_ORDER =
  'https://p1kswwr00a.execute-api.ap-northeast-1.amazonaws.com/prod/inputorders/';
// 発注管理.注文履歴.検索画面
export const API_URL_SEARCH_ORDER =
  'https://p1kswwr00a.execute-api.ap-northeast-1.amazonaws.com/prod/searchorders/';
// 発注管理.注文履歴.詳細（発注者情報）*/
export const API_URL_USER_DEPARTMENT =
  'https://p1kswwr00a.execute-api.ap-northeast-1.amazonaws.com/prod/userdepartments/';
// 発注管理.注文履歴.ステータス更新
export const API_URL_ORDER_STATUS =
  'https://p1kswwr00a.execute-api.ap-northeast-1.amazonaws.com/prod/orders/{0}/status';
// 発注管理.注文履歴.キャンセル
export const API_URL_ORDER_CANCEL =
  'https://p1kswwr00a.execute-api.ap-northeast-1.amazonaws.com/prod/orders/{0}/cancel/';
