<!-- 検索フォーム -->
<div class="GenerateSearch">
  <form [formGroup]="generateSearchForm">
    <!-- 検索情報出力領域 -->
    <ng-container *ngFor="let generateSearch of generateSearchList">
      <!-- 検索項目タイプを判定 -->
      <!-- テキスト -->
      <div class="text" *ngIf="generateSearch.search_type == 'text'">
        <div class="textName">
          {{ generateSearch.column_name }}
        </div>
        <div class="textValue">
          <input
            [name]="generateSearch.column_id"
            type="text"
            size="30"
            [formControlName]="generateSearch.column_id"
            pInputText
          />
        </div>
      </div>

      <!-- 日付 -->
      <div class="date" *ngIf="generateSearch.search_type == 'date'">
        <div class="dateName">{{ generateSearch.column_name }}</div>
        <div class="dateValue">
          開始:<p-calendar
            [name]="generateSearch.column_id + '_FROM'"
            [showIcon]="true"
            dateFormat="yy/mm/dd"
            dataType="string"
            [formControlName]="generateSearch.column_id + '_FROM'"
            translate
          ></p-calendar>
          <br />
          <div class="verticalTilde">〜</div>
          終了:<p-calendar
            [name]="generateSearch.column_id + '_TO'"
            [showIcon]="true"
            dateFormat="yy/mm/dd"
            dataType="string"
            [formControlName]="generateSearch.column_id + '_TO'"
            translate
          ></p-calendar>
        </div>
      </div>

      <!-- 数値 -->
      <div class="number" *ngIf="generateSearch.search_type == 'number'">
        <div class="numberName">{{ generateSearch.column_name }}</div>
        <div class="numberValue">
          開始:<input
            [name]="generateSearch.column_id + '_FROM'"
            [formControlName]="generateSearch.column_id + '_FROM'"
            type="number"
            pInputText
          />
          <br />
          <div class="verticalTilde">〜</div>
          終了:<input
            [name]="generateSearch.column_id + '_TO'"
            [formControlName]="generateSearch.column_id + '_TO'"
            type="number"
            pInputText
          />
        </div>
      </div>

      <!-- チェックボックス -->
      <div class="checkbox" *ngIf="generateSearch.search_type == 'checkbox'">
        <div class="checkboxName">
          {{ generateSearch.column_name }}
        </div>
        <div class="checkBoxValue">
          <ng-container
            *ngFor="let code_list of generateSearch.code_list_multi"
          >
            <p class="p-field-checkbox">
              <p-checkbox
                [name]="generateSearch.column_id"
                [label]="code_list.name"
                [value]="code_list.value"
                [formControl]="
                  generateSearchForm.controls[generateSearch.column_id]
                "
              ></p-checkbox>
            </p>
          </ng-container>
        </div>
      </div>

      <!-- プルダウン -->
      <div class="pulldown" *ngIf="generateSearch.search_type == 'pulldown'">
        <div class="pulldownName">
          {{ generateSearch.column_name }}
        </div>
        <div class="pulldownValue">
          <p-multiSelect
            [name]="generateSearch.column_id"
            [options]="generateSearch.code_list_multi"
            optionLabel="name"
            [formControlName]="generateSearch.column_id"
          ></p-multiSelect>
        </div>
      </div>

      <!-- テキストエリア -->
      <div class="list" *ngIf="generateSearch.search_type == 'list'">
        <div class="listName">
          {{ generateSearch.column_name }}
        </div>
        <div class="listValue">
          <textarea
            [name]="generateSearch.column_id"
            rows="10"
            maxlength="1000"
            [formControlName]="generateSearch.column_id"
            pInputTextarea
          ></textarea>
        </div>
      </div>
    </ng-container>
  </form>

  <!-- ボタンエリア -->
  <div class="buttonArea">
    <input
      pButton
      type="submit"
      value="検索"
      class="p-button-raised p-mr-2 button"
      (click)="onSubmit()"
    />
    <input
      pButton
      type="submit"
      value="クリア"
      class="p-button-raised p-mr-2 button"
      (click)="reset()"
    />
  </div>
</div>
