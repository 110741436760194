<div class="content">
  <header>
    <app-header [headerFlag]="headerFlag"></app-header>
  </header>
  <main>
    <router-outlet></router-outlet>
  </main>
  <footer>
    <app-footer></app-footer>
  </footer>
</div>
