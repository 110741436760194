import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { API_RESPONSE } from '../../constant/api-constant';
import { MESSAGE_CODE } from '../../constant/message-constant';
import { TOAST } from '../../constant/primeng-constants';
import { CommonService } from '../../service/common.service';

@Component({
  selector: 'app-message-common',
  templateUrl: './message-common.component.html',
})

/**
 * メッセージ
 */
export class MessageCommonComponent implements OnInit {
  constructor(
    private messageService: MessageService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {}

  /**
   * レスポンス情報を判定しTOASTメッセージを設定
   * @param response レスポンス情報
   * @param successHeader 正常時のTOASTヘッダー(デフォルト:完了)
   * @param successBody 正常時のTOASTボディ(デフォルト:完了しました)
   * @returns true(レスポンスが正常)、false(レスポンスが異常)
   */
  public responseToastMessage(
    response: any,
    successHeader?: string,
    successBody?: string
  ): boolean {
    // レスポンスのHTTPステータスを判定
    if (200 != response.status) {
      // HTTPステータスが200以外の場合

      // エラーメッセージの表示
      this.messageService.add({
        severity: TOAST.ERROR,
        summary: this.commonService.msg(MESSAGE_CODE.E00003),
        detail: response.message,
      });

      // 異常終了
      return false;
    }

    // HTTPステータスが200の場合
    // レスポンス結果を判定
    if (API_RESPONSE.SUCCESS != response.body[0].Message) {
      // レスポンス結果が正常以外の場合

      if (API_RESPONSE.FAIL == response.body[0].Message) {
        // レスポンス結果が不正の場合

        // 失敗メッセージの表示
        this.messageService.add({
          severity: TOAST.WARN,
          summary: this.commonService.msg(MESSAGE_CODE.E00001),
          detail: this.commonService.msg(MESSAGE_CODE.E00002),
        });

        // 異常終了
        return false;
      } else {
        // レスポンス結果が不正(メッセージあり)の場合
        // TODO 都庁案件で時間が足りない為、暫定対応

        // 失敗メッセージの表示
        this.messageService.add({
          severity: TOAST.WARN,
          summary: this.commonService.msg(MESSAGE_CODE.E00001),
          detail: response.body[0].Message,
        });

        // 異常終了
        return false;
      }
    }

    // 正常時のTOASTヘッダーが存在しているか否か
    if (!successHeader) {
      // 正常時のTOASTヘッダーが存在していない場合

      // '完了'メッセージを取得
      successHeader = this.commonService.msg(MESSAGE_CODE.T00001);
    }

    // 正常時のTOASTボディが存在しているか否か
    if (!successBody) {
      // 正常時のTOASTボディが存在していない場合

      // '完了しました'メッセージを取得
      successBody = this.commonService.msg(MESSAGE_CODE.T00002);
    }

    // レスポンス結果が正常の場合
    this.messageService.add({
      severity: TOAST.SUCCESS,
      summary: successHeader,
      detail: successBody,
    });

    // 正常終了
    return true;
  }
}
