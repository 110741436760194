/**
 * ルート権限設定
 */
/**
 * 注意
 * 画面を追加する場合は"Component"名で定数追加
 * 内部はpath,department_level,department_typeを設定
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * path,department_level,department_typeの場合、設定値は以下の通り設定
 * 1.department_type:''(空)→チェックを行わない
 * 2.department_type:'9'(1つ)→設定値でチェック
 * 3.department_type:'1,9'(2つ以上)→該当設定値に一致するかチェック
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 */
/** 共通 */
// TOP
export const TopComponent = {
  // 画面パス
  path: '',
  // 組織レベル
  department_level: '',
  // 組織種別
  department_type: '',
};

/** マスタ */
// 営業日マスタ
export const BizdayComponent = {
  // 画面パス
  path: 'pages/master/bizday',
  // 組織レベル
  department_level: '99',
  // 組織種別
  department_type: '99',
};

// 届先マスタ
export const DeliveryComponent = {
  // 画面パス
  path: 'pages/master/delivery',
  // 組織レベル
  department_level: '',
  // 組織種別
  department_type: '2,3,9',
};

// 組織マスタ
export const DepartmentComponent = {
  // 画面パス
  path: 'pages/master/department',
  // 組織レベル
  department_level: '',
  // 組織種別
  department_type: '9',
};

// お知らせマスタ
export const InformationComponent = {
  // 画面パス
  path: 'pages/master/information',
  // 組織レベル
  department_level: '',
  // 組織種別
  department_type: '9',
};

// 販促資材マスタ
export const ItemComponent = {
  // 画面パス
  path: 'pages/master/item',
  // 組織レベル
  department_level: '',
  // 組織種別
  department_type: '1,3,9',
};

// ユーザーマスタ
export const UserComponent = {
  // 画面パス
  path: 'pages/master/user',
  // 組織レベル
  department_level: '',
  // 組織種別
  department_type: '9',
};

/** 発注管理 */
// カート
export const CartComponent = {
  // 画面パス
  path: 'pages/order/cart',
  // 組織レベル
  department_level: '',
  // 組織種別
  department_type: '2,9',
};

// 届先検索
export const DeliverySearchComponent = {
  // 画面パス
  path: 'pages/order/delivery-search',
  // 組織レベル
  department_level: '',
  // 組織種別
  department_type: '2,9',
};

// 販促資材検索
export const ItemSearchComponent = {
  // 画面パス
  path: 'pages/order/item-search',
  // 組織レベル
  department_level: '',
  // 組織種別
  department_type: '2,9',
};

// 発注実績
export const PerformanceComponent = {
  // 画面パス
  path: 'pages/order/performance',
  // 組織レベル
  department_level: '',
  // 組織種別
  department_type: '',
};

// 発注
export const QuantityComponent = {
  // 画面パス
  path: 'pages/order/quantity',
  // 組織レベル
  department_level: '',
  // 組織種別
  department_type: '2,9',
};

// リンク
export const LinkComponent = {
  // 画面パス
  path: '',
  // 組織レベル
  department_level: '',
  // 組織種別
  department_type: '',
};

// メッセージ画面
export const MessageComponent = {
  // 画面パス
  path: '',
  // 組織レベル
  department_level: '',
  // 組織種別
  department_type: '',
};
