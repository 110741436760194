<div class="p-d-flex">
  <!-- メニュー-->
  <div class="menu">
    <app-menu></app-menu>
  </div>

  <!-- お知らせ詳細-->
  <div class="notice">
    <app-notice></app-notice>
  </div>
</div>
