import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { API_RESPONSE } from 'src/app/shared/constant/api-constant';
import { GENERATE_INPUT_TYPE } from '../../constant';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-generate-input-response-message',
  templateUrl: './generate-input-response-message.component.html',
})

/**
 * 入力レスポンスメッセージ
 */
export class GenerateInputResponseMessageComponent implements OnInit {
  constructor(
    private messageService: MessageService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {}

  /**
   * メッセージ表示
   * @param inputType 登録種別（新規:new、編集:edit、複製:duplicate）
   * @param response レスポンス情報
   * @returns true(レスポンスが正常)、false(レスポンスが異常)
   */
  public responseMessage(inputType: string, response: any): boolean {
    // レスポンスのHTTPステータスを判定
    if (200 != response.status) {
      // HTTPステータスが200以外の場合

      // エラーメッセージの表示
      this.messageService.add({
        severity: TOAST.ERROR,
        summary: this.commonService.msg(MESSAGE_CODE.E00003),
        detail: response.message,
      });

      // 異常終了
      return false;
    }

    // HTTPステータスが200の場合
    // レスポンス結果を判定
    if (API_RESPONSE.SUCCESS != response.body[0].Message) {
      // レスポンス結果が正常以外の場合

      if (API_RESPONSE.FAIL == response.body[0].Message) {
        // レスポンス結果が不正の場合

        // 失敗メッセージの表示
        this.messageService.add({
          severity: TOAST.WARN,
          summary: this.commonService.msg(MESSAGE_CODE.E00001),
          detail: this.commonService.msg(MESSAGE_CODE.E00004),
        });

        // 異常終了
        return false;
      } else {
        // レスポンス結果が不正(メッセージあり)の場合
        // TODO 都庁案件で時間が足りない為、暫定対応

        // 失敗メッセージの表示
        this.messageService.add({
          severity: TOAST.WARN,
          summary: this.commonService.msg(MESSAGE_CODE.E00001),
          detail: response.body[0].Message,
        });

        // 異常終了
        return false;
      }
    }

    // レスポンス結果が正常の場合
    // 登録種別を判定
    if (GENERATE_INPUT_TYPE.NEW == inputType) {
      // 登録種別が"新規"の場合

      this.messageService.add({
        severity: TOAST.SUCCESS,
        summary: this.commonService.msg(MESSAGE_CODE.T00001),
        detail: this.commonService.msg(MESSAGE_CODE.I00001),
      });
    } else if (GENERATE_INPUT_TYPE.EDIT == inputType) {
      // 登録種別が"編集"の場合

      this.messageService.add({
        severity: TOAST.SUCCESS,
        summary: this.commonService.msg(MESSAGE_CODE.T00001),
        detail: this.commonService.msg(MESSAGE_CODE.U00001),
      });
    } else {
      // 登録種別が"複製"の場合

      this.messageService.add({
        severity: TOAST.SUCCESS,
        summary: this.commonService.msg(MESSAGE_CODE.T00001),
        detail: this.commonService.msg(MESSAGE_CODE.I00002),
      });
    }

    // 正常終了
    return true;
  }
}
