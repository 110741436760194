/**
 * 画面用メッセージ定数
 */
/** 権限チェックエラー */
export const ROUTING_ERROR = {
  // 権限情報設定無し
  ROUTING_NONE: '権限情報が設定されておりません',
  // 権限パスが不正
  ROUTING_AUTHORITY_PATH_ERROR: 'URLが不正です',
  // 権限が不正
  ROUTING_AUTHORITY_ERROR: 'ログインユーザ情報の権限が不正です',
};

/** HTTPステータスエラー */
export const HTTP_ERROR = {
  // 401
  RESPONSE_NONE: 'APIレスポンスが存在しません',
  // 400
  HTTP_400: 'APIに不正なリクエストが行われました',
  // 401
  HTTP_401: '認証に失敗しました',
  // 403
  HTTP_403: '不正なリクエストが送信されました',
  // 404
  HTTP_404: 'APIが存在しません',
  // 412
  HTTP_412: 'システム稼働時間外となります',
  // 422
  HTTP_422: 'API取得失敗',
  // 500
  HTTP_500: 'API更新失敗',
  // ERROR
  ERROR: 'APIに予期しないエラーが発生しました',
};

/** メッセージコード */
// 先頭文字
// T 下記のいずれにも一致しない
// S DB検索系のメッセージ
// I DB登録系メッセージ
// U DB更新系メッセージ
// D DB削除系メッセージ
// N 通常メッセージ
// E エラーメッセージ
export const MESSAGE_CODE = {
  // 完了
  T00001: 'T00001',
  // 完了しました
  T00002: 'T00002',
  // 反映まで5分かかります
  T00003: 'T00003',
  // {0}:{1}
  T00004: 'T00004',
  // チェックされた行があります。<br><font color="#FF0000">注：チェック行は｢選択ボタン｣を押下するまで反映されません。<br>&ensp;&ensp;&ensp;&ensp;画面遷移しても、よろしいでしょうか？</font>
  T00005: 'T00005',
  // 登録完了しました
  I00001: 'I00001',
  // 複製完了しました
  I00002: 'I00002',
  // 画像登録完了しました
  I00003: 'I00003',
  // 編集完了しました
  U00001: 'U00001',
  // 更新完了しました
  U00002: 'U00002',
  // キャンセルしました
  U00003: 'U00003',
  // {0}:{1}<br>キャンセルしてもよろしいですか？
  U00004: 'U00004',
  // 削除完了しました
  D00001: 'D00001',
  // {0}:{1}<br>削除してもよろしいですか？
  D00002: 'D00002',
  // {0}<br>削除してもよろしいですか？
  D00003: 'D00003',
  // ログアウトしました
  N90000: 'N90000',
  // ユーザのログイン有効期限が過ぎております<br>下記から再度ログインをお願い致します
  N90001: 'N90001',
  // システム稼働時間外となります<br>システム稼働時間内に再度ログインをお願い致します
  N90002: 'N90002',
  // ログイン認証時間が終了しました<br>下記から再度ログインをお願い致します
  N90003: 'N90003',
  // 失敗
  E00001: 'E00001',
  // 失敗しました
  E00002: 'E00002',
  // エラー
  E00003: 'E00003',
  // 登録失敗しました
  E00004: 'E00004',
  // 削除失敗しました
  E00005: 'E00005',
  // キャンセル失敗しました
  E00006: 'E00006',
  // status:{0} body:{1} url:{3}
  E00007: 'E00007',
  // status:{0} message:{1}
  E00008: 'E00008',
  // 画像登録失敗しました
  E00009: 'E00009',
  // 画像拡張子は{0}のみとなります
  E00010: 'E00010',
  // 画像サイズは{0}以下までとなります
  E00011: 'E00011',
  // 不正なユーザとなります<br>下記から再ログインをお願い致します
  E90000: 'E90000',
  // ログインユーザに権限がございません<br>下記から再ログインをお願い致します
  E90001: 'E90001',
  // 不正なリクエストが行われました<br>下記から再ログインをお願い致します
  E90002: 'E90002',
};

/** メッセージ */
// 先頭文字
// T 下記のいずれにも一致しない
// S DB検索系のメッセージ
// I DB登録系メッセージ
// U DB更新系メッセージ
// D DB削除系メッセージ
// N 通常メッセージ
// E エラーメッセージ
export const MESSAGE = {
  // 完了
  T00001: '完了',
  // 完了しました
  T00002: '完了しました',
  // 反映まで5分かかります
  T00003: '反映まで5分かかります',
  // {0}:{1}
  T00004: '{0}:{1}',
  // チェックされた行があります。<br><font color="#FF0000">注：チェック行は｢選択ボタン｣を押下するまで反映されません。<br>&ensp;&ensp;&ensp;&ensp;画面遷移しても、よろしいでしょうか？</font>
  T00005:
    'チェックされた行があります。<br><font color="#FF0000">注：チェック行は｢選択ボタン｣を押下するまで反映されません。<br>&ensp;&ensp;&ensp;&ensp;画面遷移しても、よろしいでしょうか？</font>',
  // 登録完了しました
  I00001: '登録完了しました',
  // 複製完了しました
  I00002: '複製完了しました',
  // 画像登録完了しました
  I00003: '画像登録完了しました',
  // 編集完了しました
  U00001: '編集完了しました',
  // 更新完了しました
  U00002: '更新完了しました',
  // キャンセルしました
  U00003: 'キャンセルしました',
  // {0}:{1}<br>キャンセルしてもよろしいですか？
  U00004: '{0}:{1}<br>キャンセルしてもよろしいですか？',
  // 削除完了しました
  D00001: '削除完了しました',
  // {0}:{1}<br>削除してもよろしいですか？
  D00002: '{0}:{1}<br>削除してもよろしいですか？',
  // {0}<br>削除してもよろしいですか？
  D00003: '{0}<br>削除してもよろしいですか？',
  // ログアウトしました
  N90000: 'ログアウトしました',
  // ユーザのログイン有効期限が過ぎております<br>下記から再度ログインをお願い致します
  N90001:
    'ユーザのログイン有効期限が過ぎております<br>下記から再度ログインをお願い致します',
  // システム稼働時間外となります<br>システム稼働時間内に再度ログインをお願い致します
  N90002:
    'システム稼働時間外となります<br>システム稼働時間内に再度ログインをお願い致します',
  // ログイン認証時間が終了しました<br>下記から再度ログインをお願い致します
  N90003:
    'ログイン認証時間が終了しました<br>下記から再度ログインをお願い致します',
  // 失敗
  E00001: '失敗',
  // 失敗しました
  E00002: '失敗しました',
  // エラー
  E00003: 'エラー',
  // 登録失敗しました
  E00004: '登録失敗しました',
  // 削除失敗しました
  E00005: '削除失敗しました',
  // キャンセル失敗しました
  E00006: 'キャンセル失敗しました',
  // status:{0} body:{1} url:{3}
  E00007: 'status:{0} body:{1} url:{2}',
  // status:{0} message:{1}
  E00008: 'status:{0} message:{1}',
  // 画像登録失敗しました
  E00009: '画像登録失敗しました',
  // 画像拡張子は{0}のみとなります
  E00010: '画像拡張子は{0}のみとなります',
  // 画像サイズは{0}以下までとなります
  E00011: '画像サイズは{0}以下までとなります',
  // 不正なユーザとなります<br>下記から再ログインをお願い致します
  E90000: '不正なユーザとなります<br>下記から再度ログインをお願い致します',
  // ログインユーザに権限がございません<br>下記から再ログインをお願い致します
  E90001:
    'ログインユーザに権限がございません<br>下記から再度ログインをお願い致します',
  // 不正なリクエストが行われました<br>下記から再ログインをお願い致します
  E90002:
    '不正なリクエストが行われました<br>下記から再度ログインをお願い致します',
};
