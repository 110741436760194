import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/** モジュール */
// ルーティング
import { sharedRoutingModule } from './shared-routing.module';

// 共通
import { LibraryModule } from 'src/app/library/library.module';

/** コンポーネント */
// GENERATE
import { GenerateDisplayComponent } from './generate/generate-display/generate-display.component';
import { GenerateDisplayInformationComponent } from './generate/generate-display/generate-display-information/generate-display-information.component';
import { GenerateInputComponent } from './generate/generate-input/generate-input.component';
import { GenerateInputInformationComponent } from './generate/generate-input/generate-input-information/generate-input-information.component';
import { GenerateInputFormComponent } from './generate/generate-input/generate-input-information/generate-input-form/generate-input-form.component';
import { GenerateInputErrorMessageComponent } from './generate/generate-input/generate-input-information/generate-input-error-message/generate-input-error-message.component';
import { GenerateInputResponseMessageComponent } from './generate/generate-input/generate-input-information/generate-input-response-message/generate-input-response-message.component';
import { GenerateSearchComponent } from './generate/generate-search/generate-search.component';

// HTML_PARTS
import { DeleteDialogComponent } from './html-parts/delete-dialog/delete-dialog.component';
import { FooterComponent } from './html-parts/footer/footer.component';
import { HeaderComponent } from './html-parts/header/header.component';
import { MessageCommonComponent } from './html-parts/message-common/message-common.component';
import { MessageContainerComponent } from './html-parts/message-container/message-container.component';

@NgModule({
  declarations: [
    // GENERATE
    GenerateDisplayComponent,
    GenerateDisplayInformationComponent,
    GenerateInputComponent,
    GenerateInputInformationComponent,
    GenerateInputFormComponent,
    GenerateInputErrorMessageComponent,
    GenerateInputResponseMessageComponent,
    GenerateSearchComponent,

    // HTML_PARTS
    DeleteDialogComponent,
    FooterComponent,
    HeaderComponent,
    MessageCommonComponent,
    MessageContainerComponent,
  ],
  imports: [CommonModule, sharedRoutingModule, LibraryModule],
  exports: [
    // GENERATE
    GenerateDisplayComponent,
    GenerateDisplayInformationComponent,
    GenerateInputComponent,
    GenerateInputInformationComponent,
    GenerateInputFormComponent,
    GenerateInputErrorMessageComponent,
    GenerateInputResponseMessageComponent,
    GenerateSearchComponent,

    // HTML_PARTS
    DeleteDialogComponent,
    FooterComponent,
    HeaderComponent,
    MessageCommonComponent,
    MessageContainerComponent,
  ],
})
export class SharedModule {}
