<!-- 入力情報 -->
<div class="GenerateInputInformation">
  <!-- 入力エラーメッセージ-->
  <app-generate-input-error-message></app-generate-input-error-message>
  <!-- 入力フォーム-->
  <app-generate-input-form
    (generateInputInformation)="insertGenerateInputInformation($event)"
    (generateInputErrorInformation)="
      outputGenerateInputErrorInformation($event)
    "
  ></app-generate-input-form>
</div>

<!-- 入力レスポンスメッセージ-->
<app-generate-input-response-message></app-generate-input-response-message>
