import { Injectable } from '@angular/core';
import { MESSAGE } from 'src/app/shared/constant/message-constant';
import { CONSTANT } from 'src/app/shared/constant/constant';
import { API_RESPONSE } from 'src/app/shared/constant/api-constant';

@Injectable({
  providedIn: 'root',
})

/**
 * 共通機能
 */
export class CommonService {
  constructor() {}

  /**
   * if分岐において数値:0をtrueとする
   * @param value 分岐判定値
   * @returns boolean
   * ※通常のif分岐では数値0はfalseとなる為、
   * 共通部品を作成
   */
  public ifZeroPermission(value): boolean {
    // 分岐判定値の型を判定
    if ('[object Number]' == Object.prototype.toString.call(value)) {
      // 数値型の場合

      // 値が0か否か
      if (0 == value) {
        // 値が0の場合
        return true;
      }
      // 値が0以外の場合

      return value ? true : false;
    }

    // 数値型以外の場合
    return value ? true : false;
  }

  /**
   * response判定(検索系の判定)
   * @param response レスポンス情報
   * @returns true レスポンスが存在しない場合
   * @returns false レスポンスが存在する場合
   */
  public checkNoneResponse(response: any): boolean {
    // responseが存在するか否か
    if (0 == response.body.length) {
      // responseが存在しない場合

      return true;
    }

    // responseのレコードが存在するか否か
    if (API_RESPONSE.NO_RECORD == response.body[0].Message) {
      // responseレコードが存在しない場合

      return true;
    }

    // responseが存在する場合
    return false;
  }

  /**
   * response判定(登録、更新、削除系の判定)
   * @param response レスポンス情報
   * @returns true レスポンス正常
   * @returns false レスポンス異常
   */
  public checkRunningNormallyResponse(response: any): boolean {
    // レスポンスのHTTPステータスを判定
    if (200 != response.status) {
      // HTTPステータスが200以外の場合

      // 異常終了
      return false;
    }

    // HTTPステータスが200の場合
    // レスポンス結果が存在するか否か
    if (0 == response.body.length) {
      // レスポンス結果が存在しない場合

      // 異常終了
      return false;
    }

    // レスポンス結果を判定
    if (API_RESPONSE.SUCCESS != response.body[0].Message) {
      // レスポンス結果が正常以外の場合

      // 異常終了
      return false;
    }

    // 正常終了
    return true;
  }

  /**
   * response結合(検索系)
   * @param responseList レスポンス情報
   * @param leadJoinNumber 先頭結合行数
   *
   * leadJoinNumber:先頭結合行数に3を入れた場合、0~2行目を結合
   * 上記以外を、そのまま返却
   *
   * memo:db-operation.createForkJoinTask作成したforkJoinのレスポンス結合に使用する
   * @returns response レスポンス結合情報
   */
  public JoinSearchResponseList(
    responseList: any[],
    leadJoinNumber: number
  ): any {
    // レスポンス情報が存在するか否か
    if (!responseList.length) {
      // レスポンス情報が存在しない場合

      return null;
    }

    // 返却用レスポンスリスト
    let returnResponseList: any = new Array();

    // response分ループ
    responseList.forEach((responseData, index) => {
      // ループ回数が0回目か否か
      if (!index) {
        // ループ回数が0回目の場合

        // status等の込みデータを格納
        returnResponseList[0] = responseData;
      } else {
        // ループ回数が1回目以降の場合

        // ループ回数が先頭結合行数以下か否か
        if (index < leadJoinNumber) {
          // 先頭結合行数以下の場合

          // コード値の一覧情報が存在するか否か
          if (!this.checkNoneResponse(responseData)) {
            // コード値の一覧情報が存在する場合

            // 返却用レスポンスにレスポンスボディをマージする
            returnResponseList[0].body = returnResponseList[0].body.concat(
              responseData.body
            );
          }
        } else {
          // 返却用レスポンスリストにレスポンス情報を追加する
          returnResponseList[returnResponseList.length] = responseData;
        }
      }
    });

    // 結合したレスポンス用返却用オブジェクトを返却
    return returnResponseList;
  }

  /**
   * API URL生成
   * @param endPoint REST APIエンドポイント
   * @param replaceArray 置き換える文字列
   * @returns URL
   */
  public url(endPoint: string, ...replaceArray: any[]): string {
    // 置き換える文字列分ループ
    replaceArray.forEach((replace, index) => {
      // REST APIエンドポイントに置き換え文字列が存在するか否か
      if (endPoint.includes('{' + index + '}')) {
        // 置き換え文字列が存在する場合

        // 文字を置き換える
        endPoint = endPoint.replace('{' + index + '}', replace);
      } else {
        // 置き換え文字列が存在しない場合

        // 文字列の最後に連結する
        endPoint = endPoint.concat(replace);
      }
    });

    // URLを返却
    return endPoint;
  }

  /**
   * メッセージ内容取得
   * @param msgkey メッセージキー
   * @param replaceArray 置き換える文字列
   * @returns メッセージ内容
   */
  public msg(msgkey: string, ...replaceArray: any[]): string {
    // メッセージ情報取得
    let message = MESSAGE[msgkey];

    // メッセージ情報が存在するか否か
    if (!message) {
      // メッセージ情報が存在しない場合

      return '';
    }

    // 置き換える文字列分ループ
    replaceArray.forEach((replace, index) => {
      message = message.replace('{' + index + '}', replace);
    });

    return message;
  }

  /**
   * BASE64に画像変換
   * @param file ファイル情報
   */
  public getBase64(file): any {
    return new Promise((resolve) => {
      // FileReader オブジェクトを生成
      const reader = new FileReader();
      // ファイルをbase64URL化しFileReaderに格納
      reader.readAsDataURL(file);
      // ファイル情報の読み込みが正常終了の場合、Base64化したファイル情報を返却
      reader.onload = (event) => resolve(event.target.result);
      // ファイル情報の読み込みが異常終了の場合、コンソールにエラー出力
      reader.onerror = (error) => console.error(error);
    });
  }

  /**
   * ArrayObjectから該当キーで配列を生成
   * @param ArrayObject 検索対象ArrayObject
   * @param searchKey 検索キー
   * @returns 該当データ配列
   *
   * 例 検索キー:header
   * [{field: "user_id", header: "ユーザID"},{field: "user_name", header: "ユーザ名称"}]
   * → ["ユーザID", "ユーザ名称"]
   */
  public createArrayGetArrayObject(
    ArrayObject: {}[],
    searchKey: string
  ): string[] {
    // 検索対象ArrayObjectと検索キーの必須判定
    if (!ArrayObject || !searchKey) {
      // 検索対象ArrayObjectか検索キーが存在しない場合

      return null;
    }

    // ArrayObjectから対象のsearchKeyでArrayを作成
    return ArrayObject.map((val) => {
      return [val[searchKey]].join(CONSTANT.COMMA);
    });
  }

  /**
   * ArrayObjectから該当データを取得する
   * @param ArrayObject 検索対象ArrayObject
   * @param searchKey 検索キー
   * @param getKey 取得キー
   * @param searchValue 検索値
   * @returns 該当データ
   *
   * 例 検索キー:field,取得キー:header,検索値:user_name
   * [{field: "user_id", header: "ユーザID"},{field: "user_name", header: "ユーザ名称"}]
   * → ユーザ名称
   */
  public getArrayObjectValue(
    ArrayObject: {}[],
    searchKey: string,
    getKey: string,
    searchValue: string | number
  ): any {
    // ArrayObjectから対象のobjectを取得
    var object = ArrayObject.filter((val) => val[searchKey] == searchValue);

    // objectが取得できたか否か
    if (0 == object.length) {
      return null;
    }

    // objectから対象データを取得
    return object[0][getKey];
  }

  /**
   * ArrayObjectから該当データを複数取得する
   * @param ArrayObject 検索対象ArrayObject
   * @param searchKey 検索キー
   * @param getKey 取得キー
   * @param searchValue 検索値
   * @returns 該当データ配列
   *
   * 例 検索キー:field,取得キー:header,検索値:user_name
   * [{field: "user_id", header: "ユーザID"},{field: "user_name", header: "ユーザ名称"},{field: "user_name", header: "ユーザカナ"}]
   * → ["ユーザ名称", "ユーザカナ"]
   */
  public getArrayObjectMultipleValue(
    ArrayObject: {}[],
    searchKey: string,
    getKey: string,
    searchValue: []
  ): string[] {
    // 取得データを格納
    let array = new Array();

    // 検索対象ArrayObjectをループ
    for (const object of ArrayObject) {
      // 検索値をループ
      searchValue.forEach((data) => {
        // 検索対象ArrayObjectの検索キーと検索値を判定
        if (object[searchKey] == data) {
          // 検索対象ArrayObjectの検索キーと検索値が一致した場合

          // 検索対象ArrayObjectの取得キーを配列に格納
          array.push(object[getKey]);
        }
      });
    }

    // 取得データが存在するか確認
    if (0 == array.length) {
      // データが存在しない場合
      return null;
    }

    // 取得データを返却
    return array;
  }
}
