import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/shared/service/common.service';
import { GenerateService } from '../../../generate.service';
import {
  DISPLAY_INFORMATIONS_API_CONSTANT,
  INPUT_INFORMATIONS_API_CONSTANT,
} from 'src/app/shared/constant/api-constant';
import { GENERATE_INPUT_TYPE } from '../../constant';
import { CONSTANT } from 'src/app/shared/constant/constant';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { CommonValidator } from 'src/app/shared/validator/common-validator';
import { GenerateInputErrorMessage } from '../generate-input-error-message/generate-input-error-message';
import { GenerateInputFormConfirmation } from './generate-input-form-confirmation';

@Component({
  selector: 'app-generate-input-form',
  templateUrl: './generate-input-form.component.html',
  styleUrls: ['./generate-input-form.component.scss'],
})

/**
 * 入力フォーム
 */
export class GenerateInputFormComponent implements OnInit {
  // 画面.入力フォーム
  generateInputForm: FormGroup = new FormGroup({});

  // アウトプット.入力フォーム情報
  @Output() generateInputInformation = new EventEmitter<FormGroup>();

  // アウトプット.入力フォームエラー情報
  @Output() generateInputErrorInformation = new EventEmitter<
    GenerateInputErrorMessage[]
  >();

  // 画面.入力項目生成
  generateInputList: any[];

  // 表示項目生成
  generateDisplayList: any[];

  // 入力フォーム確認画面出力オブジェクトリスト
  generateInputFormConfirmationList: GenerateInputFormConfirmation[];

  // 確認画面表示フラグ
  confirmationFlag: boolean;

  // 登録ボタン押下フラグ(データ登録中にボタン押下させないフラグ)
  insertFlag: boolean;

  constructor(
    private generateService: GenerateService,
    private commonService: CommonService,
    private cmnValdator: CommonValidator,
    private translateService: TranslateService,
    private config: PrimeNGConfig
  ) {
    // PrimeNGを日本語化
    this.translateService
      .get('primeng')
      .subscribe((res) => this.config.setTranslation(res));
  }

  ngOnInit(): void {}

  /**
   * 入力項目生成
   * @param inputType 登録種別（新規:new、編集:edit、複製:duplicate）
   * @param endPointInput 入力項目生成用エンドポイント
   * @param endPoint 編集、複製用検索エンドポイント
   * @param templateId テンプレートID
   * @param pkeyId 対象ID
   */
  public initial(
    inputType: string,
    endPointInput: string,
    endPoint: string,
    templateId: number,
    pkeyId: string
  ) {
    // 画面.入力フォームを初期化
    this.generateInputForm = new FormGroup({});

    // 入力フォーム状態初期化
    this.resetFlag();

    // 入力項目情報取得処理
    this.generateService
      .getGenerateInput(endPointInput, templateId)
      .subscribe((response) => {
        // 入力項目情報が取得されたか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 入力項目情報が取得されなかった場合

          return;
        }

        // 入力項目情報を画面.入力項目生成に格納
        this.generateInputList = response.body;

        // 入力フォーム用コントロール作成
        for (const generateInput of response.body) {
          // 入力フォームグループに入力フォームコントロールを追加
          this.generateInputForm.addControl(
            generateInput.column_id,
            new FormControl(generateInput.column_defualt, {
              asyncValidators: [
                this.cmnValdator.CommonValidator(
                  generateInput.table_id,
                  generateInput.column_id,
                  generateInput.input_type
                ),
              ],
            })
          );

          // 入力項目タイプの判定
          if (
            generateInput.input_type ==
            INPUT_INFORMATIONS_API_CONSTANT.CHECKBOX_TYPE
          ) {
            // 入力項目タイプが"checkbox"の場合

            // 入力フォームコントロールにチェックが外れた場合のバリデーションチェックを実施を追加
            this.generateInputForm.get(generateInput.column_id).updateOn;
          }

          // 登録種別が編集 かつ
          // 編集不可項目か否か
          if (
            GENERATE_INPUT_TYPE.EDIT == inputType &&
            generateInput.column_input_update == '0'
          ) {
            // 登録種別が編集の場合 かつ
            // 編集不可項目の場合

            // 入力フォームコントロールを非活性化
            this.generateInputForm.get(generateInput.column_id).disable();
          }
        }

        // 登録種別の判定
        if (GENERATE_INPUT_TYPE.EDIT == inputType) {
          // 登録種別が編集の場合
          // 更新用の詳細情報取得
          this.editGenerateDisplay(endPoint, templateId, pkeyId);
        } else if (GENERATE_INPUT_TYPE.DUPLICATE == inputType) {
          // 登録種別が複製の場合
          // 複製用の詳細情報取得
          this.duplicateGenerateDisplay(endPoint, templateId, pkeyId);
        }
      });
  }

  /**
   * 更新用の詳細情報取得
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param pkeyId 対象データのID
   */
  private editGenerateDisplay(
    endPoint: string,
    templateId: number,
    pkeyId: string
  ) {
    // 更新用の詳細情報取得
    this.generateService
      .getGenerateDisplay(endPoint, templateId, pkeyId)
      .subscribe((response) => {
        // 詳細情報が取得されたか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 詳細情報が取得されなかった場合

          return;
        }

        // 詳細情報をプロパティに格納
        this.generateDisplayList = response.body;

        // 詳細情報初期値セット
        this.setFormControlValue(response.body);
      });
  }

  /**
   * 複製用の詳細情報取得
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param pkeyId 対象データのID
   */
  private duplicateGenerateDisplay(
    endPoint: string,
    templateId: number,
    pkeyId: string
  ) {
    // 複製用の詳細情報取得
    this.generateService
      .getGenerateDisplay(endPoint, templateId, pkeyId, true)
      .subscribe((response) => {
        // 詳細情報が取得されたか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 詳細情報が取得されなかった場合

          return;
        }

        // 詳細情報をプロパティに格納
        this.generateDisplayList = response.body;

        // 詳細情報初期値セット
        this.setFormControlValue(response.body);
      });
  }

  /**
   * 詳細情報初期値セット
   * @param generateDisplayInformation
   */
  private setFormControlValue(generateDisplayInformation: any[]) {
    // 入力項目分ループ
    for (const generateInput of this.generateInputList) {
      // 入力項目タイプを判定
      if (
        generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE ||
        generateInput.input_type ==
          INPUT_INFORMATIONS_API_CONSTANT.NUMBER_TYPE ||
        generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.DATE_TYPE ||
        generateInput.input_type ==
          INPUT_INFORMATIONS_API_CONSTANT.TEXTAREA_TYPE ||
        generateInput.input_type ==
          INPUT_INFORMATIONS_API_CONSTANT.PASSWORD_TYPE
      ) {
        // 入力項目タイプが以下の場合
        // テキスト or
        // 数値 or
        // 日付 or
        // テキストエリア
        // パスワードの場合

        // 詳細情報のカラム値をフォームコントロールに格納
        this.generateInputForm
          .get(generateInput.column_id)
          .setValue(
            this.commonService.getArrayObjectValue(
              generateDisplayInformation,
              DISPLAY_INFORMATIONS_API_CONSTANT.COLUMN_ID,
              DISPLAY_INFORMATIONS_API_CONSTANT.DATA,
              generateInput.column_id
            )
          );
      } else {
        // 入力項目タイプが以下の場合
        // ラジオボタン or
        // チェックボックス or
        // シングルセレクト or
        // マルチセレクトの場合

        // 詳細情報のカラム値(コード)を取得する
        const dataMulti = this.commonService.getArrayObjectValue(
          generateDisplayInformation,
          DISPLAY_INFORMATIONS_API_CONSTANT.COLUMN_ID,
          DISPLAY_INFORMATIONS_API_CONSTANT.DATA_MULTI,
          generateInput.column_id
        );

        // 入力項目タイプを判定
        if (
          generateInput.input_type ==
            INPUT_INFORMATIONS_API_CONSTANT.RADIO_TYPE ||
          generateInput.input_type ==
            INPUT_INFORMATIONS_API_CONSTANT.SINGLE_SELECT_TYPE
        ) {
          // 入力項目タイプが以下の場合
          // ラジオボタン or
          // シングルセレクトの場合

          // カラム値(コード)を文字列に変換してフォームコントロールに格納
          this.generateInputForm
            .get(generateInput.column_id)
            .setValue(dataMulti.join(CONSTANT.COMMA));
        } else {
          // 入力項目タイプが以下の場合
          // チェックボックス or
          // マルチセレクトの場合

          // カラム値(コード)をフォームコントロールに格納
          this.generateInputForm
            .get(generateInput.column_id)
            .setValue(dataMulti);
        }
      }
    }
  }

  /**
   * 入力項目値確認
   */
  public inputVerification() {
    // 入力項目値バリデーションチェック
    this.generateService
      .multivalidationResult(
        this.generateInputList[0].table_id,
        this.generateInputForm.value
      )
      .subscribe((data) => {
        // 入力フォームエラー情報リスト生成
        let generateInputErrorMessageList: GenerateInputErrorMessage[] = new Array();

        // バリデーションチェック状態を確認
        if ('0' != data.body[0].result) {
          // チェック状態が異常の場合

          // 入力項目分ループ
          for (const item of this.generateInputList) {
            // 入力項目に該当するエラーメッセージが存在するか判定
            if (
              undefined != data.body[0].message[item.column_id] &&
              '' != data.body[0].message[item.column_id]
            ) {
              // エラーメッセージが存在する場合

              //  入力フォームエラー情報を生成
              let generateInputErrorMessage: GenerateInputErrorMessage = new GenerateInputErrorMessage();
              //  入力フォームエラー情報を格納
              generateInputErrorMessage.columnName = item.column_name;
              generateInputErrorMessage.columnErrorMessage =
                data.body[0].message[item.column_id];
              generateInputErrorMessageList.push(generateInputErrorMessage);
            }
          }

          // 親コンポーネントへ入力フォームエラー情報を渡す
          this.generateInputErrorInformation.emit(
            generateInputErrorMessageList
          );

          // 処理を終了
          return;
        }

        // 親コンポーネントへ入力フォームエラー情報を渡す(空リストの返却により入力フォームエラー情報をクリア)
        this.generateInputErrorInformation.emit(generateInputErrorMessageList);

        // 入力値確認用リスト生成
        this.generateInputFormConfirmationList = new Array();

        // 入力項目分ループ
        for (const generateInput of this.generateInputList) {
          // 入力値確認用オブジェクト
          let generateInputFormConfirmation: GenerateInputFormConfirmation = new GenerateInputFormConfirmation();

          // 入力項目タイプを判定
          if (
            generateInput.input_type ==
              INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE ||
            generateInput.input_type ==
              INPUT_INFORMATIONS_API_CONSTANT.NUMBER_TYPE ||
            generateInput.input_type ==
              INPUT_INFORMATIONS_API_CONSTANT.DATE_TYPE ||
            generateInput.input_type ==
              INPUT_INFORMATIONS_API_CONSTANT.TEXTAREA_TYPE
          ) {
            // 入力項目タイプが以下の場合
            // テキスト or
            // 数値 or
            // 日付 or
            // テキストエリアの場合

            // 入力項目値の名称を格納
            generateInputFormConfirmation.columnName =
              generateInput.column_name;
            // FormControlの入力値を取得
            let value = this.generateInputForm.value[generateInput.column_id];

            // FormControlの入力値が取得不可(フォームが非活性の場合)
            if (undefined == value) {
              // 表示項目から値を取得
              value = this.commonService.getArrayObjectValue(
                this.generateDisplayList,
                DISPLAY_INFORMATIONS_API_CONSTANT.COLUMN_ID,
                DISPLAY_INFORMATIONS_API_CONSTANT.DATA,
                generateInput.column_id
              );
            }

            // FormControlの入力値を格納
            generateInputFormConfirmation.columnData = value;
            // 入力値確認用オブジェクトを入力値確認用リストに追加
            this.generateInputFormConfirmationList.push(
              generateInputFormConfirmation
            );
            continue;
          } else if (
            generateInput.input_type ==
            INPUT_INFORMATIONS_API_CONSTANT.PASSWORD_TYPE
          ) {
            // パスワードの場合

            // 入力項目値の名称を格納
            generateInputFormConfirmation.columnName =
              generateInput.column_name;

            // FormControlの入力値を取得
            let value = this.generateInputForm.value[generateInput.column_id];

            // FormControlの入力値が取得不可(フォームが非活性の場合)
            if (undefined == value) {
              // 表示項目から値を取得
              value = this.commonService.getArrayObjectValue(
                this.generateDisplayList,
                DISPLAY_INFORMATIONS_API_CONSTANT.COLUMN_ID,
                DISPLAY_INFORMATIONS_API_CONSTANT.DATA,
                generateInput.column_id
              );
            }

            // FormControlの入力値を'●'に置換して格納
            generateInputFormConfirmation.columnData = '●'.repeat(value.length);
            // 入力値確認用オブジェクトを入力値確認用リストに追加
            this.generateInputFormConfirmationList.push(
              generateInputFormConfirmation
            );
            continue;
          } else if (
            generateInput.input_type ==
              INPUT_INFORMATIONS_API_CONSTANT.RADIO_TYPE ||
            generateInput.input_type ==
              INPUT_INFORMATIONS_API_CONSTANT.SINGLE_SELECT_TYPE
          ) {
            // 種類が以下の場合
            // ラジオボタン or
            // シングルセレクトの場合

            // 入力項目値の名称を格納
            generateInputFormConfirmation.columnName =
              generateInput.column_name;

            // FormControlの入力値を取得
            let value = this.generateInputForm.value[generateInput.column_id];

            // FormControlの入力値が取得不可(フォームが非活性の場合)
            if (undefined == value) {
              // 表示項目から値を取得
              value = this.commonService.getArrayObjectValue(
                this.generateDisplayList,
                DISPLAY_INFORMATIONS_API_CONSTANT.COLUMN_ID,
                DISPLAY_INFORMATIONS_API_CONSTANT.DATA_MULTI,
                generateInput.column_id
              );
            }

            // FormControlの入力値を名称に変換して格納
            generateInputFormConfirmation.columnData = this.commonService.getArrayObjectValue(
              generateInput.column_code_list_multi,
              INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_VALUE,
              INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_NAME,
              value
            );
            // 入力値確認用オブジェクトを入力値確認用リストに追加
            this.generateInputFormConfirmationList.push(
              generateInputFormConfirmation
            );
          } else {
            // 種類が以下の場合
            // チェックボックス or
            // マルチセレクトの場合

            // 入力項目値の名称を格納
            generateInputFormConfirmation.columnName =
              generateInput.column_name;

            // FormControlの入力値を取得
            let value = this.generateInputForm.value[generateInput.column_id];

            // FormControlの入力値が取得不可(フォームが非活性の場合)
            if (undefined == value) {
              // 表示項目から値を取得
              value = this.commonService.getArrayObjectValue(
                this.generateDisplayList,
                DISPLAY_INFORMATIONS_API_CONSTANT.COLUMN_ID,
                DISPLAY_INFORMATIONS_API_CONSTANT.DATA_MULTI,
                generateInput.column_id
              );
            }
            // FormGroupの入力値(複数)を名称に変換して格納
            generateInputFormConfirmation.columnData = this.commonService
              .getArrayObjectMultipleValue(
                generateInput.column_code_list_multi,
                INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_VALUE,
                INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_NAME,
                value
              )
              .join(CONSTANT.COMMA);
            // 入力値確認用オブジェクトを入力値確認用リストに追加
            this.generateInputFormConfirmationList.push(
              generateInputFormConfirmation
            );
          }
        }

        // 確認画面を表示
        this.confirmationFlag = true;
      });
  }

  /**
   * 入力フォーム情報返却
   */
  public returnGenerateInputForm() {
    // 登録ボタン押下フラグをONにする(登録、戻るボタン非活性化)
    this.insertFlag = true;

    // 親コンポーネントへ入力フォーム情報を渡す
    this.generateInputInformation.emit(this.generateInputForm);
  }

  /**
   * 入力フォーム状態初期化
   */
  public resetFlag() {
    // 確認画面表示フラグを初期化
    this.confirmationFlag = false;

    // 登録ボタン押下フラグを初期化
    this.insertFlag = false;
  }
}
