import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_URL_MULTIVALIDATION_RESULT } from 'manager/http-constants';
import { CommonService } from '../service/common.service';

@Injectable({
  providedIn: 'root',
})

/**
 * アイテムアダプター専用_サービスクラス
 */
export class GenerateService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * 検索項目生成
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @returns JSONデータ
   */
  getGenerateSearch(endPoint: string, templateId: number): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(endPoint, '?TemplateID=', templateId);

    // 取得データの返却
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * 表示項目生成
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param pkeyId 対象データのID
   * @param duplicate 複製処理(入力項目生成(複製)の場合、true)
   * @returns JSONデータ
   */
  getGenerateDisplay(
    endPoint: string,
    templateId: number,
    pkeyId: string,
    duplicate?: boolean
  ): Observable<any> {
    // LambdaのEndpoint格納先を宣言
    let url;

    // 入力項目生成(複製)処理か否か
    if (duplicate) {
      // 入力項目生成(複製)処理の場合

      // 表示項目生成(複製)のEndpointを生成
      url = this.commonService.url(
        endPoint,
        pkeyId,
        '/duplicate',
        '?TemplateID=',
        templateId
      );
    } else {
      // 表示項目生成のEndpointを生成
      url = this.commonService.url(
        endPoint,
        pkeyId,
        '?TemplateID=',
        templateId
      );
    }

    // 取得データの返却
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * 入力項目生成
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @returns JSONデータ
   */
  getGenerateInput(endPoint: string, templateId: number): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(endPoint, '?TemplateID=', templateId);

    // 取得データの返却
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * 入力項目値バリデーションチェック
   * @param tableId テーブル物理名
   * @param inputItem 入力データ
   * @returns JSONデータ
   */
  multivalidationResult(tableId: string, inputItem: {}): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(API_URL_MULTIVALIDATION_RESULT, tableId);

    // 取得データの返却
    return this.http
      .post(url, inputItem, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * DB登録を実施
   * @param endPoint REST APIエンドポイント
   * @param inputItem 入力データ
   * @returns JSONデータ
   */
  insert(endPoint: string, inputItem: any): Observable<any> {
    // 登録結果を返却する
    return this.http
      .post(endPoint, inputItem, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * DB更新を実施
   * @param endPoint REST APIエンドポイント
   * @param pkeyId 対象データのID
   * @param inputItem 入力データ
   * @returns JSONデータ
   */
  update(endPoint: string, pkeyId: string, inputItem: any): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(endPoint, pkeyId);

    // 更新結果を返却する
    return this.http
      .put(url, inputItem, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }
}
