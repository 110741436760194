import { Component, OnInit } from '@angular/core';
import { GenerateService } from 'src/app/shared/generate/generate.service';
import { OutputGenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-generate-display-information',
  templateUrl: './generate-display-information.component.html',
  styleUrls: ['./generate-display-information.component.scss'],
})

/**
 * 詳細情報
 */
export class GenerateDisplayInformationComponent implements OnInit {
  constructor(
    private generateService: GenerateService,
    private commonService: CommonService
  ) {}

  // 詳細情報出力オブジェクトリスト(画面表示情報)
  outputGenerateDisplayList: OutputGenerateDisplay[] = new Array();

  ngOnInit(): void {}

  /**
   * 詳細情報表示
   * @param inputGenerateDisplay 詳細情報入力オブジェクト(オブジェクトorリスト)
   * @param pkeyId 選択対象ID
   */
  public initial(inputGenerateDisplay: any, pkeyId: string) {
    // 詳細情報出力オブジェクトリスト(画面表示情報)を初期化
    this.outputGenerateDisplayList = [];

    // 型判定
    if (
      '[object Object]' == Object.prototype.toString.call(inputGenerateDisplay)
    ) {
      // オブジェクト型の場合

      // 詳細情報取得を実施
      this.getDisplayInformation(inputGenerateDisplay, pkeyId, 0);
    } else if (
      '[object Array]' == Object.prototype.toString.call(inputGenerateDisplay)
    ) {
      // リスト型の場合

      // 詳細情報取得を複数実施
      inputGenerateDisplay.forEach((element, index) => {
        this.getDisplayInformation(
          element,
          // 詳細情報オブジェクトにpkeyIdが設定されているか否か
          element.pkeyId
            ? // 詳細情報オブジェクトにpkeyIdが設定されている場合
              element.pkeyId
            : // 上記以外の場合、第一引数のpkeyIdを使用
              pkeyId,
          index
        );
      });
    }
  }

  /**
   * 詳細情報取得
   * @param inputGenerateDisplay 詳細情報入力オブジェクト(オブジェクトorリスト)
   * @param pkeyId 選択対象ID
   * @param index 出力順番
   */
  private getDisplayInformation(
    inputGenerateDisplay: any,
    pkeyId: string,
    index: number
  ) {
    // 詳細情報出力オブジェクト(画面表示情報)を生成
    let outputGenerateDisplay: OutputGenerateDisplay =
      new OutputGenerateDisplay();

    // 詳細情報出力オブジェクト.タイトルを設定(初期値は"詳細")
    outputGenerateDisplay.title = inputGenerateDisplay.title;

    // 詳細情報取得処理
    this.generateService
      .getGenerateDisplay(
        inputGenerateDisplay.endPoint,
        inputGenerateDisplay.templateId,
        pkeyId
      )
      .subscribe((response) => {
        // 詳細情報が取得されたか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 詳細情報が取得された場合

          // 詳細情報出力オブジェクト.詳細出力情報を設定
          outputGenerateDisplay.generateDisplay = response.body;

          // 詳細情報出力オブジェクトを詳細情報出力オブジェクトリストに追加
          this.outputGenerateDisplayList.splice(
            index,
            0,
            outputGenerateDisplay
          );
        }
      });
  }
}
