<!-- ステータス更新画面 -->
<p-dialog [header]="title" [(visible)]="updateModal" [modal]="true">
  <!-- 入力エラーメッセージ-->
  <app-generate-input-error-message></app-generate-input-error-message>

  <!-- 詳細情報-->
  <app-generate-display-information></app-generate-display-information>

  <!-- 入力フォーム-->
  <app-generate-input-form
    (generateInputInformation)="insertGenerateInputInformation($event)"
    (generateInputErrorInformation)="
      outputGenerateInputErrorInformation($event)
    "
  ></app-generate-input-form>
</p-dialog>

<!-- メッセージ -->
<app-message-common></app-message-common>
