<!-- 発注管理リスト -->
<app-breadcrumb [selectionLine]="cartSelected.length"></app-breadcrumb>

<!-- メイン領域-->
<mat-drawer-container class="main-container" autosize>
  <!-- 一覧画面表示領域-->
  <div class="details-layout">
    <p-table
      #table
      [value]="searchResultsList"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
      [rowsPerPageOptions]="[10, 50, 100]"
      styleClass="p-datatable-striped order-datatable"
      selectionMode="multiple"
      [(first)]="pages"
      [(selection)]="cartSelected"
    >
      <ng-template pTemplate="caption">
        <div class="p-d-flex p-jc-between">
          <div class="p-d-flex">
            <!-- 検索画面表示-->
            <button
              type="button"
              pButton
              icon="pi pi-search"
              (click)="search.toggle()"
              class="p-mr-2"
              pTooltip="検索"
              tooltipPosition="bottom"
            ></button>
            <!-- カート追加 -->
            <button
              type="button"
              pButton
              label="選択"
              icon="pi pi-plus-circle"
              (click)="addCart()"
              class="p-mr-2 addCartButton"
            ></button>
          </div>
          <div>
            <!-- 全件csv出力-->
            <button
              type="button"
              pButton
              icon="pi pi-file-o"
              (click)="exportCSV()"
              class="p-mr-2"
              pTooltip="全件CSV"
              tooltipPosition="bottom"
            ></button>
          </div>
        </div>
      </ng-template>

      <!-- 一覧画面ヘッダー表示-->
      <ng-template pTemplate="header">
        <tr class="p-col-2">
          <!-- APIヘッダーの表示 -->
          <th style="width: 3rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <ng-container *ngFor="let column of columnOrder">
            <th [pSortableColumn]="column.field">
              {{ column.header }}
              <p-sortIcon [field]="column.field"></p-sortIcon>
            </th>
          </ng-container>
        </tr>
      </ng-template>

      <!-- 一覧画面ボディ表示-->
      <ng-template pTemplate="body" let-searchResults>
        <tr [pSelectableRow]="searchResults">
          <!-- チェックボックス -->
          <td>
            <p-tableCheckbox
              disabled="false"
              [value]="searchResults"
            ></p-tableCheckbox>
          </td>
          <ng-container
            *ngFor="let column of columnOrder; index as i"
            [ngSwitch]="true"
          >
            <!-- 一覧項目値表示(詳細画面遷移項目) -->
            <td *ngIf="0 == i; else hideContent">
              <label (click)="detail(searchResults[column.field])">
                <u>{{ searchResults[column.field] }}</u>
              </label>
            </td>
            <!-- 一覧項目値表示 -->
            <ng-template #hideContent [ngSwitch]="column?.input_type">
              <td *ngSwitchCase="'NUMBER'">
                {{ searchResults[column.field] | number }}
              </td>
              <td *ngSwitchDefault>
                {{ searchResults[column.field] }}
              </td>
            </ng-template>
          </ng-container>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <!-- 検索画面-->
  <mat-drawer #search class="search-sidenav" mode="side">
    <!-- 検索画面非表示-->
    <button
      type="button"
      pButton
      icon="pi pi-arrow-left"
      (click)="search.toggle()"
      class="p-mr-2"
    ></button>

    <!-- 検索画面[検索項目生成(アイテムアダプター)]-->
    <app-generate-search
      [searchEndPoint]="searchEndPoint"
      [searchTemplateId]="searchTemplateId"
      (generateSearchInformation)="searchResult($event)"
    ></app-generate-search>
  </mat-drawer>
</mat-drawer-container>

<!-- ダイアログ -->
<p-confirmDialog
  #cd
  icon="pi pi-exclamation-triangle"
  class="dialog"
  [autoZIndex]="true"
  [focusTrap]="true"
>
  <p-footer>
    <div class="p-mb-3 buttonArea">
      <button
        type="button"
        label="はい"
        (click)="cd.accept()"
        class="p-button-raised p-mr-2 button"
        pButton
      ></button>
      <button
        type="button"
        label="いいえ"
        (click)="cd.reject()"
        class="p-button-outlined p-button-secondary button"
        pButton
      ></button>
    </div>
  </p-footer>
</p-confirmDialog>

<!-- 詳細画面[表示項目生成(アイテムアダプター)]-->
<app-generate-display></app-generate-display>

<!-- メッセージ -->
<app-message-common></app-message-common>

<!-- Toastメッセージ表示 -->
<p-toast position="top-center"></p-toast>
