import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/** モジュール */
// ルーティング
import { PagesRoutingModule } from './pages-routing.module';

// 共通
import { SharedModule } from 'src/app//shared/shared.module';
import { LibraryModule } from 'src/app/library/library.module';

/** コンポーネント */
// TOP
import { TopComponent } from './top/top.component';
import { NoticeComponent } from './top/notice/notice.component';
import { NoticeListComponent } from './top/notice/notice-list/notice-list.component';
import { MenuComponent } from './top/menu/menu.component';

// MASTER
import { BizdayComponent } from './master/bizday/bizday.component';
import { DeliveryComponent } from './master/delivery/delivery.component';
import { DepartmentComponent } from './master/department/department.component';
import { InformationComponent } from './master/information/information.component';
import { ItemComponent } from './master/item/item.component';
import { ItemDetailComponent } from './master/item/item-detail/item-detail.component';
import { StockComponent } from './master/item/stock/stock.component';
import { UserComponent } from './master/user/user.component';

// ORDER
import { BreadcrumbComponent } from './order/breadcrumb.component';
import { CartComponent } from './order/cart/cart.component';
import { CartDeliveryComponent } from './order/cart/cart-delivery/cart-delivery.component';
import { CartItemComponent } from './order/cart/cart-item/cart-item.component';
import { DeliverySearchComponent } from './order/delivery-search/delivery-search.component';
import { ItemSearchComponent } from './order/item-search/item-search.component';
import { PerformanceComponent } from './order/performance/performance.component';
import { QuantityComponent } from './order/quantity/quantity.component';
import { UpdateStatusComponent } from './order/performance/update-status/update-status.component';

// LINK
import { LinkComponent } from './link/link.component';

// MESSAGE
import { MessageComponent } from './message/message.component';

@NgModule({
  declarations: [
    // TOP
    TopComponent,
    NoticeComponent,
    NoticeListComponent,
    MenuComponent,

    // MASTER
    BizdayComponent,
    DeliveryComponent,
    DepartmentComponent,
    InformationComponent,
    ItemComponent,
    ItemDetailComponent,
    StockComponent,
    UserComponent,

    // ORDER
    BreadcrumbComponent,
    CartComponent,
    CartDeliveryComponent,
    CartItemComponent,
    DeliverySearchComponent,
    ItemSearchComponent,
    QuantityComponent,
    PerformanceComponent,
    UpdateStatusComponent,

    // LINK
    LinkComponent,

    // MESSAGE
    MessageComponent,
  ],
  imports: [CommonModule, PagesRoutingModule, SharedModule, LibraryModule],
  providers: [],
})
export class PagesModule {}
