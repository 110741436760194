<div class="details-layout">
  <p-table
    #table
    [value]="searchResultsList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
    [rowsPerPageOptions]="[10, 50, 100]"
    styleClass="p-datatable-striped order-datatable"
    selectionMode="single"
  >
    <!-- 選択届け先一覧 -->
    <ng-template pTemplate="caption">
      <div>選択届先一覧</div>
    </ng-template>

    <!-- 一覧画面ヘッダー表示-->
    <ng-template pTemplate="header">
      <tr class="p-col-2">
        <!-- APIヘッダーの表示 -->
        <ng-container *ngFor="let column of columnOrder">
          <th pSortableColumn="{{ column.field }}">
            {{ column.header }}
            <p-sortIcon field="{{ column.field }}"></p-sortIcon>
          </th>
        </ng-container>

        <!-- 操作ヘッダーの表示 -->
        <th>
          <p>操作</p>
        </th>
      </tr>
    </ng-template>

    <!-- ボディ -->
    <ng-template pTemplate="body" let-searchResults>
      <tr>
        <!-- 一覧表示-->
        <ng-container *ngFor="let column of columnOrder; index as i">
          <!-- 一覧項目値表示(プライマリーキー) -->
          <td *ngIf="0 == i; else hideContent">
            <label>
              {{ searchResults[column.field] }}
            </label>
          </td>
          <!-- 一覧項目値表示 -->
          <ng-template #hideContent>
            <td [ngSwitch]="column?.input_type">
              <span *ngSwitchCase="'NUMBER'">
                {{ searchResults[column.field] | number }}
              </span>
              <span *ngSwitchDefault>
                {{ searchResults[column.field] }}
              </span>
            </td>
          </ng-template>
        </ng-container>

        <!-- 操作表示 -->
        <td>
          <!-- 削除ボタン -->
          <button
            pButton
            (click)="delete(searchResults[columnOrder[0].field])"
            icon="pi pi-times"
            class="p-button-rounded p-button-danger"
            pTooltip="削除"
            tooltipPosition="bottom"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<!-- 削除ダイアログ-->
<app-delete-dialog (reloadID)="searchReplacement($event)"></app-delete-dialog>

<!-- Toastメッセージ表示 -->
<p-toast position="top-center"></p-toast>
