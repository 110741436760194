import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import {
  ROUTING_ERROR,
  HTTP_ERROR,
  MESSAGE_CODE,
} from 'src/app/shared/constant/message-constant';
import { CONSTANT } from 'src/app/shared/constant/constant';
import { LoginService } from 'src/app/shared/service/login.service';
import { SESSION_KEY } from '../../../../manager/environment';
import * as routing from '../../../../manager/routing-constant';
import { CommonService } from './common.service';

/**
 * ルーティング遷移時の介入
 */
@Injectable({
  providedIn: 'root',
})
export class Routing {
  constructor(
    private readonly _router: Router,
    private loginService: LoginService,
    private commonService: CommonService
  ) {
    this._router.events
      .pipe(filter((event) => event instanceof ActivationStart))
      .subscribe((event) => {
        // Auth0ログインユーザトークン報処理
        this.loginService.getAuth0LoginToken().subscribe((data) => {
          // Auth0ログインユーザトークンが存在するか否か
          if (!data) {
            // Auth0ログインユーザトークンが存在しない場合

            // ログイン時間終了の為、ログアウト処理
            this.loginService.logout(MESSAGE_CODE.N90003);

            // 処理を終了
            return;
          }

          // セッションからログインユーザ情報取得
          let loginUser = JSON.parse(
            window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
          );

          // セッションにログインユーザ情報が存在するか否か
          if (!loginUser) {
            // セッションにログインユーザが存在しない場合(初回表示時)

            // ログインユーザ情報取得処理
            this.loginService.getLoginUser().subscribe((response) => {
              // ログインユーザ情報がユーザマスタに存在するか否か
              if (this.commonService.checkNoneResponse(response)) {
                // ユーザマスタに存在しない場合

                // 不正なユーザの為、ログアウト処理
                this.loginService.logout(MESSAGE_CODE.E90000);

                // 処理を終了
                return;
              } else {
                // ユーザマスタに存在する場合

                // 正常なユーザの為、セッションにユーザ情報を格納
                window.sessionStorage.setItem(
                  SESSION_KEY.loginUserInformation,
                  JSON.stringify(response.body[0])
                );
              }

              // 画面遷移権限チェックを実施
              this.checkRouting(response.body[0], event);
            });
          } else {
            // 画面遷移権限チェックを実施
            this.checkRouting(loginUser, event);
          }
        });
      });
  }

  /**
   * 画面遷移権限チェック
   * @param loginUser ログインユーザ
   * @param event 遷移先コンポーネント情報
   */
  private checkRouting(loginUser, event) {
    // 定数から権限情報を取得
    const routingCheckInformation =
      routing[event.snapshot.routeConfig.component.name];

    // 権限情報が設定されているか否か
    if (!routingCheckInformation) {
      // 権限情報が設定されていない場合

      // エラーメッセージを出力
      console.error(ROUTING_ERROR.ROUTING_NONE);

      // 不正な権限の為、ログアウト処理
      this.loginService.logout(MESSAGE_CODE.E90001);

      return;
    }

    // 権限情報に画面パスが設定されているか否か
    if (CONSTANT.EMPTY_STRING != routingCheckInformation.path) {
      // 権限情報に画面パスが設定されている場合

      const pathList = routingCheckInformation.path.split(CONSTANT.COMMA);

      // 権限情報の画面パスと遷移先画面パスが一致しているか否か
      if (!pathList.includes(event.snapshot.routeConfig.path)) {
        // 画面パスが一致していない場合

        // エラーメッセージを出力
        console.error(ROUTING_ERROR.ROUTING_AUTHORITY_PATH_ERROR);

        // 不正な権限の為、ログアウト処理
        this.loginService.logout(MESSAGE_CODE.E90001);

        return;
      }
    }

    // 権限情報に組織レベルが設定されているか否か
    if (CONSTANT.EMPTY_STRING != routingCheckInformation.department_level) {
      // 権限情報に組織レベルが設定されている場合

      const departmentLevelList = routingCheckInformation.department_level.split(
        CONSTANT.COMMA
      );

      // 権限情報の組織レベルとログインユーザの組織レベルが一致しているか否か
      if (!departmentLevelList.includes(loginUser.department_level)) {
        // 組織レベルが一致していない場合

        // エラーメッセージを出力
        console.error(ROUTING_ERROR.ROUTING_AUTHORITY_ERROR);

        // 不正な権限の為、ログアウト処理
        this.loginService.logout(MESSAGE_CODE.E90001);

        return;
      }
    }

    // 権限情報に組織種別が設定されているか否か
    if (CONSTANT.EMPTY_STRING != routingCheckInformation.department_type) {
      // 権限情報に組織種別が設定されている場合

      const departmentTypeList = routingCheckInformation.department_type.split(
        CONSTANT.COMMA
      );

      // 権限情報の組織種別とログインユーザの組織種別が一致しているか否か
      if (!departmentTypeList.includes(loginUser.department_type)) {
        // 組織種別が一致していない場合

        // エラーメッセージを出力
        console.error(ROUTING_ERROR.ROUTING_AUTHORITY_ERROR);

        // 不正な権限の為、ログアウト処理
        this.loginService.logout(MESSAGE_CODE.E90001);

        return;
      }
    }
  }
}

/**
 * Httpリクエスト時の介入
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private loginService: LoginService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Auth0ログインユーザトークン報処理
    this.loginService.getAuth0LoginToken().subscribe((data) => {
      // Auth0ログインユーザトークンが存在するか否か
      if (!data) {
        // Auth0ログインユーザトークンが存在しない場合

        // ログイン時間終了の為、ログアウト処理
        this.loginService.logout(MESSAGE_CODE.N90003);

        // 処理を終了
        return;
      }
    });

    // リクエストを実施
    return next.handle(req);
  }
}

/**
 * Httpレスポンス時の介入
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private loginService: LoginService,
    private commonService: CommonService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const req = request.clone();
    return next.handle(req).pipe(
      // tapオペレータでレスポンスの流れを傍受する
      tap((res) => {
        // 通常レスポンスの場合
        if (res instanceof HttpResponse) {
          // レスポンスが正常に返却されているか判定
          if (null == res.body || 0 == res.body.length) {
            // レスポンスが存在しない場合

            // コンソールにエラー出力
            console.error(HTTP_ERROR.RESPONSE_NONE);
            console.error(
              this.commonService.msg(
                MESSAGE_CODE.E00007,
                res.status,
                JSON.stringify(res.body),
                res.url
              )
            );
            console.error(res);
          }
        }
      }),
      // lambdaで異常ステータスが返却された場合
      catchError((res) => {
        // 異常ステータスのステータス判定
        switch (res.status) {
          case 400:
            console.error(HTTP_ERROR.HTTP_400);
            console.error(
              this.commonService.msg(MESSAGE_CODE.E00008, res.status, res.error)
            );
            break;

          case 401:
            console.error(HTTP_ERROR.HTTP_401);
            console.error(
              this.commonService.msg(MESSAGE_CODE.E00008, res.status, res.error)
            );
            // 認証エラーの為、ログアウト処理
            this.loginService.logout(MESSAGE_CODE.N90001);
            break;

          case 403:
            console.error(HTTP_ERROR.HTTP_401);
            console.error(
              this.commonService.msg(MESSAGE_CODE.E00008, res.status, res.error)
            );
            // 不正パラメータでの取得の為、ログアウト処理
            this.loginService.logout(MESSAGE_CODE.E90002);
            break;

          case 404:
            console.error(HTTP_ERROR.HTTP_404);
            console.error(
              this.commonService.msg(MESSAGE_CODE.E00008, res.status, res.error)
            );
            break;

          case 412:
            console.error(HTTP_ERROR.HTTP_412);
            console.error(
              this.commonService.msg(MESSAGE_CODE.E00008, res.status, res.error)
            );
            // システム稼働時間外の為、ログアウト処理
            this.loginService.logout(MESSAGE_CODE.N90002);
            break;

          case 422:
            console.error(HTTP_ERROR.HTTP_422);
            console.error(
              this.commonService.msg(MESSAGE_CODE.E00008, res.status, res.error)
            );
            break;

          case 500:
            console.error(HTTP_ERROR.HTTP_500);
            console.error(
              this.commonService.msg(MESSAGE_CODE.E00008, res.status, res.error)
            );
            break;
          default:
            console.error(HTTP_ERROR.ERROR);
            console.error(
              this.commonService.msg(MESSAGE_CODE.E00008, res.status, res.error)
            );
            break;
        }

        return throwError(res);
      })
    );
  }
}
