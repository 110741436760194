<!-- 発注管理リスト -->
<app-breadcrumb></app-breadcrumb>

<p-messages *ngIf="dispErrors && errorMessages.length" severity="error">
  <ng-template pTemplate>
    <div class="error-message-box p-d-flex p-jc-between">
      <div class="p-message-detail p-d-flex p-flex-column">
        <span *ngFor="let message of errorMessages">
          <ng-container *ngIf="message.index || message.index === 0">
            {{ message.index + 1 }}行目：{{ message.columnName }}：{{
              message.detail
            }}
          </ng-container>
          <ng-container *ngIf="!message.index && message.index !== 0">
            {{ message.detail }}
          </ng-container>
        </span>
      </div>
      <button
        class="p-message-close p-link"
        (click)="dispErrors = false"
        type="button"
        pRipple
      >
        <i class="p-message-close-icon pi pi-times"></i>
      </button>
    </div>
  </ng-template>
</p-messages>

<!-- 一覧画面表示領域-->
<div class="details-layout">
  <p-table
    #ordertable
    [value]="formArray.controls"
    styleClass="p-datatable-striped order-datatable"
    [(first)]="first"
    [totalRecords]="formArray.controls.length"
    autoLayout="true"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-jc-between">
        <!-- 注意文言 -->
        <div class="">
          <p>{{attentionText1}}</p><p>{{attentionText2}}</p>
        </div>
        <div class="p-as-center">
          <!-- リセットボタン-->
          <button
            type="button"
            pButton
            [label]="resetLabel"
            class="p-mr-2"
            (click)="onClickReset($event)"
          ></button>
          <!-- 確認ボタン-->
          <button
            type="button"
            pButton
            [label]="registLabel"
            class="p-mr-2"
            (click)="onClickRegist($event)"
            [disabled]="continuousPress"
            ></button>
        </div>
      </div>
    </ng-template>

    <!-- 一覧画面ヘッダー表示-->
    <ng-template pTemplate="header">
      <tr class="p-col-2">
        <!-- APIヘッダーの表示 -->
        <ng-container *ngFor="let inputItem of inputItemsList">
          <th *ngIf="inputItem.column_pkey != 'P'">
            {{ inputItem.column_name }}
          </th>
        </ng-container>
        <th *ngIf="editFlg">操作</th>
      </tr>
    </ng-template>

    <!-- 一覧画面ボディ表示-->
    <ng-template pTemplate="body" let-rowControl>
      <tr>
        <!-- 一覧表示-->
        <ng-container *ngFor="let inputItem of inputItemsList; index as i">
          <td
            *ngIf="
              inputItem.column_input_update == '1' &&
                inputItem.column_pkey != 'P' &&
                editFlg;
              else hideContent
            "
          >
            <div [class.invalid]="isInvalid(rowControl, inputItem.column_id)">
              <div *ngIf="inputItem.input_type == 'text'" class="col-md-11">
                <input
                  id="{{
                    getIndexFromFormGroup(rowControl) +
                      '.' +
                      inputItem.column_id
                  }}"
                  type="text"
                  pInputText
                  [formControl]="rowControl.controls[inputItem.column_id]"
                  maxlength="{{ inputItem.column_hsize }}"
                />
              </div>
              <div
                *ngIf="inputItem.input_type == 'date'"
                class="ui-fluid col-md-11"
              >
                <p-calendar
                  id="{{
                    getIndexFromFormGroup(rowControl) +
                      '.' +
                      inputItem.column_id
                  }}"
                  dateFormat="yy/mm/dd"
                  [showIcon]="true"
                  dataType="string"
                  [readonlyInput]="true"
                  [touchUI]="true"
                  [formControl]="rowControl.controls[inputItem.column_id]"
                  translate
                >
                </p-calendar>
              </div>
              <div
                *ngIf="inputItem.input_type == 'number'"
                class="ui-fluid col-md-11"
              >
                <p-inputNumber
                  [formControl]="rowControl.controls[inputItem.column_id]"
                  mode="decimal"
                  inputId="{{
                    getIndexFromFormGroup(rowControl) +
                      '.' +
                      inputItem.column_id
                  }}"
                  [useGrouping]="false"
                  maxlength="{{ inputItem.column_hsize }}"
                >
                </p-inputNumber>
              </div>
              <div
                *ngIf="
                  inputItem.input_type == 'single_select' &&
                  inputItem.column_id != 'hope_delivery_type'
                "
                class="ui-fluid col-md-11"
              >
                <p-dropdown
                  id="{{
                    getIndexFromFormGroup(rowControl) +
                      '.' +
                      inputItem.column_id
                  }}"
                  [options]="inputItem.column_code_list_multi"
                  [formControl]="rowControl.controls[inputItem.column_id]"
                  placeholder="未選択"
                  optionLabel="name"
                  styleClass="dropdown"
                >
                </p-dropdown>
              </div>
              <!-- 都庁 -->
              <div
                *ngIf="
                  inputItem.input_type == 'single_select' &&
                  inputItem.column_id == 'hope_delivery_type'
                "
                class="ui-fluid col-md-11"
              >
                <p-dropdown
                  id="{{
                    getIndexFromFormGroup(rowControl) +
                      '.' +
                      inputItem.column_id
                  }}"
                  [options]="getlist(rowControl)"
                  [formControl]="rowControl.controls[inputItem.column_id]"
                  [appendTo]="ordertable"
                  placeholder="未選択"
                  optionLabel="name"
                  styleClass="dropdown"
                >
                </p-dropdown>
              </div>
            </div>
          </td>
          <ng-template #hideContent>
            <td *ngIf="inputItem.column_pkey != 'P'">
              <ng-container *ngIf="inputItem.input_type == 'single_select'">
                {{ getName(rowControl) }}
              </ng-container>
              <ng-container *ngIf="inputItem.input_type !== 'single_select'">
                {{ rowControl.controls[inputItem.column_id].value }}
              </ng-container>
            </td>
          </ng-template>
        </ng-container>
        <!-- 操作表示 -->
        <td *ngIf="editFlg">
          <!-- 削除ボタン -->
          <button
            pButton
            (click)="delete(rowControl)"
            icon="pi pi-times"
            class="p-button-rounded p-button-danger"
            pTooltip="削除"
            tooltipPosition="bottom"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<!-- 削除ダイアログ-->
<app-delete-dialog (reloadID)="onDeleteSucces($event)"></app-delete-dialog>

<p-toast
  key="order-comp"
  position="top-center"
  (onClose)="redirect()"
></p-toast>
