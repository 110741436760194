import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { DeleteDialogComponent } from 'src/app/shared/html-parts/delete-dialog/delete-dialog.component';
import { API_URL_WORK_ORDER_DELIVERIE } from 'manager/http-constants';
import { WORK_ORDER_TEMPLATE } from 'manager/template-constant';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-cart-delivery',
  templateUrl: './cart-delivery.component.html',
  providers: [ConfirmationService, MessageService],
})

/**
 * 選択届先リスト
 */
export class CartDeliveryComponent implements OnInit {
  /** 削除ダイアログ */
  @ViewChild(DeleteDialogComponent)
  deleteDialogComponent: DeleteDialogComponent;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[];

  // 画面検索結果一覧格納先
  searchResultsList: any[];

  constructor(
    private dbOperationService: DbOperationService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    // ヘッダー情報取得処理(画面用)
    this.dbOperationService
      .getHeaderList(WORK_ORDER_TEMPLATE.DELIVERIES_TEMPLATE_ID)
      .subscribe((response) => {
        this.columnOrder = response.body;
      });

    // 検索処理を実施
    this.searchResultCartDeliveries();
  }

  /**
   * 検索処理
   * @param generateSearchItems 検索条件
   */
  searchResultCartDeliveries() {
    // 届け先資材(WORK)一覧取得処理(画面用)
    this.dbOperationService
      .getData(
        API_URL_WORK_ORDER_DELIVERIE,
        WORK_ORDER_TEMPLATE.DELIVERIES_TEMPLATE_ID,
        null
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 届け先資材(WORK)一覧情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
        } else {
          this.searchResultsList = [];
        }
      });
  }

  /**
   * 削除処理
   * @param pkeyId IDコード(クリックされたID)
   */
  delete(pkeyId: string) {
    // 削除ダイアログにより削除処理
    this.deleteDialogComponent.deleteDialog(
      API_URL_WORK_ORDER_DELIVERIE,
      this.columnOrder[0].header,
      pkeyId
    );
  }

  /**
   * 検索結果一覧データ削除
   * @param pkeyId コード値
   */
  searchReplacement(pkeyId: string) {
    // 検索結果一覧から該当データを削除
    this.searchResultsList = this.searchResultsList.filter(
      (val) => val[this.columnOrder[0].field] !== pkeyId
    );
  }
}
