<!-- 発注管理リスト -->
<div class="breadcrumb p-d-flex p-jc-between">
  <p-breadcrumb
    [model]="items"
    styleClass="breadcrumbcss"
    (onItemClick)="itemClicked($event)"
  ></p-breadcrumb>

  <!-- 数量ボタン (選択リスト画面の場合、表示)-->
  <button
    type="button"
    label="数量へ"
    class="p-mr-2"
    (click)="insertWorkOrder()"
    *ngIf="quantityButtonFlag"
    pButton
  ></button>
</div>

<!-- メッセージ -->
<app-message-common></app-message-common>
