import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { BREADCRUMB_ORDER } from './breadcrumb-constants';
import { API_URL_WORK_ORDER } from 'manager/http-constants';
import { WORK_ORDER_TEMPLATE } from 'manager/template-constant';
import { MessageCommonComponent } from 'src/app/shared/html-parts/message-common/message-common.component';
import { CommonService } from 'src/app/shared/service/common.service';
import { CONSTANT } from 'src/app/shared/constant/constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./order.component.scss'],
})

/**
 * 発注管理_パンくずリスト
 */
export class BreadcrumbComponent implements OnInit {
  /** メッセージ */
  @ViewChild(MessageCommonComponent)
  messageCommonComponent: MessageCommonComponent;

  // 選択行
  @Input() selectionLine: number;

  // パンくずリスト
  items: MenuItem[] = [
    {
      // 販促資材リストへ遷移
      label: BREADCRUMB_ORDER.SELECT_ITEM,
    },
    {
      // 届先リストへ遷移
      label: BREADCRUMB_ORDER.SELECT_DELIVERY,
    },
    {
      // 選択リストへ遷移
      label: BREADCRUMB_ORDER.SELECTED,
    },
    {
      // 数量へ遷移
      label: BREADCRUMB_ORDER.QUANTITY_INPUT,
    },
  ];

  // 数量ボタン表示フラグ
  quantityButtonFlag: boolean = false;

  constructor(
    private dbOperationService: DbOperationService,
    private router: Router,
    private commonService: CommonService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    // 選択ボタンが押下されたか否か
    if (location.pathname == '/pages/order/cart') {
      // 数量ボタン表示フラグをONにする
      this.quantityButtonFlag = true;
    }
  }

  // パンくずリストのクリックイベント
  public itemClicked(event) {
    /* 販促資材(パンくずリスト) */
    // 販促資材が押下されたか否か
    if (BREADCRUMB_ORDER.SELECT_ITEM == event.item.label) {
      // 選択行が存在するか否か
      if (!this.selectionLine) {
        // 選択行が存在しない場合

        // 発注管理_販促資材へ遷移
        this.router.navigate(['/pages/order/item-search']);

        return;
      }

      // 選択確認ダイアログを表示
      this.confirmationService.confirm({
        message: this.commonService.msg(MESSAGE_CODE.T00005),
        header: CONSTANT.EMPTY_ONE_STRING,
        icon: 'pi pi-info-circle',
        accept: () => {
          // 発注管理_販促資材へ遷移
          // 発注管理_販促資材へ遷移
          this.router.navigate(['/pages/order/item-search']);

          return;
        },
      });
    }

    /* 届先(パンくずリスト) */
    // 届先が押下されたか否か
    if (BREADCRUMB_ORDER.SELECT_DELIVERY == event.item.label) {
      // 選択行が存在するか否か
      if (!this.selectionLine) {
        // 選択行が存在しない場合

        // 発注管理_届先へ遷移
        this.router.navigate(['/pages/order/delivery-search']);

        return;
      }

      // 選択確認ダイアログを表示
      this.confirmationService.confirm({
        message: this.commonService.msg(MESSAGE_CODE.T00005),
        header: CONSTANT.EMPTY_ONE_STRING,
        icon: 'pi pi-info-circle',
        accept: () => {
          // 発注管理_届先へ遷移
          this.router.navigate(['/pages/order/delivery-search']);

          return;
        },
      });
    }

    /* 選択リスト(パンくずリスト) */
    // 選択リストが押下されたか否か
    if (BREADCRUMB_ORDER.SELECTED == event.item.label) {
      // 選択行が存在するか否か
      if (!this.selectionLine) {
        // 選択行が存在しない場合

        // 発注管理_選択リストへ遷移
        this.router.navigate(['/pages/order/cart']);

        return;
      }

      // 選択確認ダイアログを表示
      this.confirmationService.confirm({
        message: this.commonService.msg(MESSAGE_CODE.T00005),
        header: CONSTANT.EMPTY_ONE_STRING,
        icon: 'pi pi-info-circle',
        accept: () => {
          // 発注管理_選択リストへ遷移
          this.router.navigate(['/pages/order/cart']);

          return;
        },
      });
    }

    // 数量ボタンが押下されたか否か
    if (
      BREADCRUMB_ORDER.QUANTITY_INPUT == event.item.label &&
      location.pathname != '/pages/order/quantity'
    ) {
      // 数量ボタンが押下 かつ
      // 数量ページ以外の場合

      // 注文情報(WORK)登録
      this.insertWorkOrder();
    }
  }

  /**
   * 選択リスト情報で注文情報(WORK)登録
   */
  public insertWorkOrder() {
    // 注文情報(WORK)登録
    this.dbOperationService
      .insertData(API_URL_WORK_ORDER, {
        TemplateID: WORK_ORDER_TEMPLATE.INPUT_TEMPLATE_ID,
      })
      .subscribe((response) => {
        // レスポンスを判定
        if (this.messageCommonComponent.responseToastMessage(response)) {
          // レスポンスが正常終了の場合

          // 発注管理_数量へ遷移
          this.router.navigate(['pages/order/quantity']);
        }
      });
  }
}
