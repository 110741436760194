import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { SESSION_KEY } from 'manager/environment';
import { InputGenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { GenerateDisplayInformationComponent } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information.component';
import { StockComponent } from '../stock/stock.component';
import { API_URL_ITEM } from 'manager/http-constants';
import { ITEM_TEMPLATE } from 'manager/template-constant';
import { MessageService } from 'primeng/api';
import { CommonService } from 'src/app/shared/service/common.service';
import { ITEM_CONSTANT, STOCK_FLAG } from '../constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { CONSTANT, IMAGE_EXTENSION } from 'src/app/shared/constant/constant';

@Component({
  selector: 'app-item-detail',
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.scss'],
})

/**
 * 販促資材マスタ詳細画面
 */
export class ItemDetailComponent {
  // 更新対象ID
  @Output() reloadID = new EventEmitter<string>();

  /** 詳細情報 */
  @ViewChild(GenerateDisplayInformationComponent)
  generateDisplayInformationComponent: GenerateDisplayInformationComponent;

  /** 入庫登録、在庫調整 */
  @ViewChild(StockComponent)
  stockComponent: StockComponent;

  // ログインユーザ情報
  loginUser = JSON.parse(
    window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
  );

  /* 画面用プロパティ */
  // 詳細画面表示フラグ
  detailsNav: boolean;

  // 詳細ID
  pkeyId: string;

  // 登録組織コード
  createDepartmentCode: string;

  constructor(
    private dbOperationService: DbOperationService,
    private messageService: MessageService,
    private commonService: CommonService
  ) {}

  /**
   * 詳細情報表示
   * @param pkeyId 選択対象ID
   * @param createDepartmentCode 登録組織コード
   */
  public initial(pkeyId: string, createDepartmentCode: string) {
    // 詳細画面表示
    this.detailsNav = true;

    // 詳細情報
    let inputGenerateDisplay: InputGenerateDisplay = new InputGenerateDisplay();
    inputGenerateDisplay.endPoint = API_URL_ITEM;
    inputGenerateDisplay.templateId = ITEM_TEMPLATE.OUTPUT_TEMPLATE_ID;
    this.generateDisplayInformationComponent.initial(
      inputGenerateDisplay,
      pkeyId
    );

    // 選択対象IDを格納
    this.pkeyId = pkeyId;

    // 登録組織コードを格納
    this.createDepartmentCode = createDepartmentCode;
  }

  /**
   * 入庫登録
   */
  public inputStockRegist() {
    // 入庫登録画面を表示
    this.stockComponent.initial(STOCK_FLAG.STOCK_REGIST, this.pkeyId);
  }

  /**
   * 在庫調整
   */
  public inputAdjustStock() {
    // 在庫調整画面を表示
    this.stockComponent.initial(STOCK_FLAG.ADJUST_STOCK, this.pkeyId);
  }

  /**
   * ファイルアップロード
   * @param event 画像情報
   * @param fileUpload ボタン動作状態
   */
  public uploadFile(event, fileUpload) {
    // 画像拡張子を取得
    const imageExtension = event.files[0].name.substring(
      event.files[0].name.lastIndexOf(CONSTANT.PERIOD)
    );

    // 画像拡張子が".jpg" or ".jpeg"か否か判定
    if (
      !(
        IMAGE_EXTENSION.JPG == imageExtension ||
        IMAGE_EXTENSION.JPEG == imageExtension
      )
    ) {
      // 画像拡張子が".jpg" or ".jpeg"以外の場合

      // 画像拡張子エラーメッセージ
      this.messageService.add({
        severity: TOAST.ERROR,
        summary: this.commonService.msg(MESSAGE_CODE.E00009),
        detail: this.commonService.msg(MESSAGE_CODE.E00010, 'jpg又はjpeg'),
      });

      // ボタン動作状態をクリア
      fileUpload.clear();

      // 処理を終了
      return;
    }

    // 画像ファイルサイズの判定
    if (ITEM_CONSTANT.FILE_SIZE < event.files[0].size) {
      // 画像サイズが200KB以上の場合

      // 画像サイズエラーメッセージ
      this.messageService.add({
        severity: TOAST.ERROR,
        summary: this.commonService.msg(MESSAGE_CODE.E00009),
        detail: this.commonService.msg(MESSAGE_CODE.E00011, '200KB'),
      });

      // ボタン動作状態をクリア
      fileUpload.clear();

      // 処理を終了
      return;
    }

    // 画像登録
    this.dbOperationService.insertImage(this.pkeyId, event.files[0]);

    // 画像登録完了メッセージ
    this.messageService.add({
      severity: TOAST.SUCCESS,
      summary: this.commonService.msg(MESSAGE_CODE.I00003),
      detail: this.commonService.msg(MESSAGE_CODE.T00003),
    });

    // ボタン動作状態をクリア
    fileUpload.clear();
  }

  /**
   * 親画面へ返却する
   */
  public return(reloadID: string) {
    // 更新対象IDにプライマリキーをセット
    this.reloadID.emit(reloadID);
  }
}
