import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { API_URL_ITEM, API_URL_STOCK } from 'manager/http-constants';
import { STOCK_FLAG, STOCK_TITLE } from '../constant';
import { CommonValidator } from 'src/app/shared/validator/common-validator';
import { GenerateDisplayInformationComponent } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information.component';
import { InputGenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { ITEM_TEMPLATE } from 'manager/template-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { API_RESPONSE } from 'src/app/shared/constant/api-constant';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss'],
  providers: [MessageService],
})

/**
 * 入庫登録画面
 */
export class StockComponent implements OnInit {
  // 更新対象ID
  @Output() reloadID = new EventEmitter<string>();

  /** 詳細情報 */
  @ViewChild(GenerateDisplayInformationComponent)
  generateDisplayInformationComponent: GenerateDisplayInformationComponent;

  // 画面.入力フォーム
  inputStockForm: FormGroup = this.formBuilder.group({
    stock_change_num: [
      '',
      {
        asyncValidators: [
          this.cmnValdator.CommonValidator(
            'stock_maintenace',
            'real_stock_change_num1',
            'NUMBER'
          ),
        ],
      },
    ],
    stock_change_date: [
      '',
      {
        asyncValidators: [
          this.cmnValdator.CommonValidator(
            'stock_maintenace',
            'real_stock_change_date1',
            'DATE'
          ),
        ],
      },
    ],
  });

  /* 画面用プロパティ */
  // 選択対象ID
  pkeyId: string;

  // 入庫登録／在庫調整フラグ
  stockInfo: string;

  // 画面タイトル
  title: string;

  // 入庫登録画面ダイアログ表示フラグ
  inputStockModal: boolean;

  // 入力項目値格納先(確認画面用)
  regist_number: string;
  regist_date: string;

  // 確認画面表示フラグ
  confirmationFlag: boolean;

  // 登録ボタン押下フラグ(データ登録中にボタン押下させないフラグ)
  insertFlag: boolean;

  constructor(
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private dbOperationService: DbOperationService,
    private cmnValdator: CommonValidator,
    private commonService: CommonService
  ) {}

  ngOnInit() {}

  /**
   * 入庫登録／在庫調整
   * @param stockInfo 入庫登録／在庫調整フラグ
   * @param pkeyId 選択対象ID
   */
  public initial(stockInfo: string, pkeyId: string) {
    // 入庫登録／在庫調整画面を表示
    this.inputStockModal = true;

    // 入力フォーム入力値初期化処理
    this.inputStockForm.reset();

    // 入力フォーム状態初期化
    this.resetFlag();

    // タイトルを設定
    if (stockInfo == STOCK_FLAG.STOCK_REGIST) {
      // 入庫登録を設定
      this.title = STOCK_TITLE.STOCK_REGIST;
    } else {
      // 在庫調整を設定
      this.title = STOCK_TITLE.ADJUST_STOCK;
    }

    // 詳細情報表示
    let inputGenerateDisplay: InputGenerateDisplay = new InputGenerateDisplay();
    inputGenerateDisplay.endPoint = API_URL_ITEM;
    inputGenerateDisplay.templateId = ITEM_TEMPLATE.STOCK_OUTPUT_TEMPLATE_ID;
    this.generateDisplayInformationComponent.initial(
      inputGenerateDisplay,
      pkeyId
    );

    // 入庫登録／在庫調整フラグを格納
    this.stockInfo = stockInfo;

    // 選択対象IDを格納
    this.pkeyId = pkeyId;
  }

  /**
   * 入力項目値確認
   */
  public inputVerification() {
    // 確認画面用に入力値を取得
    this.regist_number = this.inputStockForm.get('stock_change_num').value;
    this.regist_date = this.inputStockForm.get('stock_change_date').value;

    // 確認画面を表示
    this.confirmationFlag = true;
  }

  /**
   * 登録処理
   */
  public insertInputForm() {
    // 登録ボタン押下フラグをONにする(登録、戻るボタン非活性化)
    this.insertFlag = true;

    // 入庫登録／在庫調整判定
    if (this.stockInfo == STOCK_FLAG.STOCK_REGIST) {
      // 入庫登録の場合

      // IDを設定
      this.inputStockForm.value['item_code'] = String(this.pkeyId);

      // 入庫登録の登録
      this.dbOperationService
        .insertData(API_URL_STOCK, this.inputStockForm.value)
        .subscribe((response) => {
          // 入力レスポンスメッセージを表示
          if (this.responseMessage(response)) {
            // 正常終了の場合

            // 更新対象IDに選択対象IDをセット
            this.reloadID.emit(this.pkeyId);

            // 入力画面ダイアログを閉じる
            this.inputStockModal = false;
          } else {
            // 異常終了の場合

            // 入力フォーム状態初期化
            this.resetFlag();
          }
        });
    } else {
      // 在庫調整の場合

      // 在庫調整の登録
      this.dbOperationService
        .editData(API_URL_STOCK + this.pkeyId, this.inputStockForm.value)
        .subscribe((response) => {
          // 入力レスポンスメッセージを表示
          if (this.responseMessage(response)) {
            // 正常終了の場合

            // 更新対象IDに選択対象IDをセット
            this.reloadID.emit(this.pkeyId);

            // 入力画面ダイアログを閉じる
            this.inputStockModal = false;
          } else {
            // 異常終了の場合

            // 入力フォーム状態初期化
            this.resetFlag();
          }
        });
    }
  }

  /**
   * メッセージ表示
   * @param response レスポンス情報
   * @returns true(レスポンスが正常)、false(レスポンスが異常)
   */
  private responseMessage(response: any): boolean {
    // レスポンスのHTTPステータスを判定
    if (200 != response.status) {
      // HTTPステータスが200以外の場合

      // エラーメッセージの表示
      this.messageService.add({
        severity: TOAST.ERROR,
        summary: this.commonService.msg(MESSAGE_CODE.E00003),
        detail: response.message,
      });

      // 異常終了
      return false;
    }

    // HTTPステータスが200の場合
    // レスポンス結果を判定
    if (API_RESPONSE.SUCCESS != response.body[0].Message) {
      // レスポンス結果が正常以外の場合

      if (API_RESPONSE.FAIL == response.body[0].Message) {
        // レスポンス結果が不正の場合

        // 失敗メッセージの表示
        this.messageService.add({
          severity: TOAST.WARN,
          summary: this.commonService.msg(MESSAGE_CODE.E00001),
          detail: this.commonService.msg(MESSAGE_CODE.E00004),
        });

        // 異常終了
        return false;
      } else {
        // レスポンス結果が不正(メッセージあり)の場合
        // TODO 都庁案件で時間が足りない為、暫定対応

        // 失敗メッセージの表示
        this.messageService.add({
          severity: TOAST.WARN,
          summary: this.commonService.msg(MESSAGE_CODE.E00001),
          detail: response.body[0].Message,
        });

        // 異常終了
        return false;
      }
    }

    // レスポンス結果が正常の場合
    this.messageService.add({
      severity: TOAST.SUCCESS,
      summary: this.commonService.msg(MESSAGE_CODE.T00001),
      detail: this.commonService.msg(MESSAGE_CODE.I00001),
    });

    // 正常終了
    return true;
  }

  /**
   * 入力フォーム状態初期化
   */
  private resetFlag() {
    // 確認画面表示フラグを初期化
    this.confirmationFlag = false;

    // 登録ボタン押下フラグを初期化
    this.insertFlag = false;
  }
}
