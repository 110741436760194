/**
 * primeng用定数
 */
/** Toast定数 */
export const TOAST = {
  /** 出力色 */
  // 正常(緑)
  SUCCESS: 'success',
  // メッセージ(青)
  INFO: 'info',
  // ワーニング(黄)
  WARN: 'warn',
  // エラー(赤)
  ERROR: 'error',
  /** 出力場所定数 */
  // 右上
  TOP_RIGHT: 'top-right',
  // 左上
  TOP_LEFT: 'top-left',
  // 右下
  BOTTOM_RIGHT: 'bottom-right',
  // 左下
  BOTTOM_LEFT: 'bottom-left',
  // 中央上
  TOP_CENTER: 'top-center',
  // 中央下
  BOTTOM_CENTER: 'bottom-center',
  // 中央
  CENTER: 'center',
  /** キー */
  // エラーメッセージ
  ERROR_KEY: 'error',
  /** レイアウト前面キー */
  FRONT_KEY: 'front',
};
