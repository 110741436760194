import { Component, OnInit } from '@angular/core';
import { SESSION_KEY } from 'manager/environment';
import { MenuItem } from 'primeng/api';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { DEPARTMENT_TYPE } from 'src/app/shared/constant/db-constant';
import { LoginService } from 'src/app/shared/service/login.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})

/**
 * TOP用メニュー
 */
export class MenuComponent implements OnInit {
  // ログインユーザ情報
  loginUser = JSON.parse(
    window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
  );

  // メニュー情報
  menuitems: MenuItem[] = new Array();

  constructor(private loginService: LoginService) {}

  ngOnInit() {
    // セッションにログインユーザー情報が取得されたか否か
    if (this.loginUser) {
      // ログインユーザー情報が取得された場合

      // メニューを生成
      this.menu();
    } else {
      // ログインユーザー情報が取得されなかった場合

      // sleep時間を設定(1秒)
      const sleep = of('').pipe(delay(1000));
      // sleep時間後処理を実施
      sleep.subscribe(() => {
        // ページを更新する
        location.reload();
      });
    }
  }

  /**
   * メニュー情報設定
   */
  private menu() {
    // 発注管理
    let order: object = {};
    order['label'] = '注文';
    order['items'] = [];
    this.menuitems.push(order);

    // マスタ
    let master: object = {};
    master['label'] = '管理';
    master['items'] = [];
    this.menuitems.push(master);

    // その他
    let others: object = {};
    others['label'] = 'その他';
    others['items'] = [];
    this.menuitems.push(others);

    // ログアウト
    let logout: object = {
      items: [
        {
          label: 'ログアウト',
          icon: 'pi pi-times-circle',
          command: (event) => {
            this.loginService.logout();
          },
        },
      ],
    };
    this.menuitems.push(logout);

    // 食品選択
    if (
      DEPARTMENT_TYPE.TYPE_DESTINATION == this.loginUser.department_type ||
      DEPARTMENT_TYPE.TYPE_OTHER == this.loginUser.department_type
    ) {
      // 2:寄贈先 or 9:その他
      {
        let item: object = {
          label: '食品選択',
          icon: 'pi pi-search',
          routerLink: 'pages/order/item-search',
          routerLinkActiveOptions: 'active',
        };
        order['items'].push(item);
      }
    }

    // 届先選択
    if (
      DEPARTMENT_TYPE.TYPE_DESTINATION == this.loginUser.department_type ||
      DEPARTMENT_TYPE.TYPE_OTHER == this.loginUser.department_type
    ) {
      // 2:寄贈先 or 9:その他
      {
        let item: object = {
          label: '届先選択',
          icon: 'pi pi-search',
          routerLink: 'pages/order/delivery-search',
          routerLinkActiveOptions: 'active',
        };
        order['items'].push(item);
      }
    }

    // 選択リスト
    if (
      DEPARTMENT_TYPE.TYPE_DESTINATION == this.loginUser.department_type ||
      DEPARTMENT_TYPE.TYPE_OTHER == this.loginUser.department_type
    ) {
      // 2:寄贈先 or 9:その他
      {
        let item: object = {
          label: '選択リスト',
          icon: 'pi pi-search',
          routerLink: 'pages/order/cart',
          routerLinkActiveOptions: 'active',
        };
        order['items'].push(item);
      }
    }

    // 注文履歴
    {
      let item: object = {
        label: '注文履歴',
        icon: 'pi pi-list',
        routerLink: 'pages/order/performance',
        routerLinkActiveOptions: 'active',
      };
      order['items'].push(item);
    }

    // 販売促進マスタ
    if (
      DEPARTMENT_TYPE.TYPE_FORMER == this.loginUser.department_type ||
      DEPARTMENT_TYPE.TYPE_OFFICE == this.loginUser.department_type ||
      DEPARTMENT_TYPE.TYPE_OTHER == this.loginUser.department_type
    ) {
      // 1:寄贈元の場合 or 3:配送事務局 or 9:その他

      let item: object = {
        label: '食品',
        icon: 'pi pi-file',
        routerLink: 'pages/master/item',
        routerLinkActiveOptions: 'active',
      };
      master['items'].push(item);
    }

    // 届先マスタ
    if (
      DEPARTMENT_TYPE.TYPE_DESTINATION == this.loginUser.department_type ||
      DEPARTMENT_TYPE.TYPE_OFFICE == this.loginUser.department_type ||
      DEPARTMENT_TYPE.TYPE_OTHER == this.loginUser.department_type
    ) {
      // 2:寄贈先 or 3:配送事務局 or 9:その他

      let item: object = {
        label: '届先',
        icon: 'pi pi-file',
        routerLink: 'pages/master/delivery',
        routerLinkActiveOptions: 'active',
      };
      master['items'].push(item);
    }

    // TODO:都庁案件で不要の為、コメントアウト
    // 営業日マスタ
    // if ('9' == this.loginUser.department_type) {
    //   // 9:その他

    //   let item: object = {
    //     label: '営業日マスタ',
    //     icon: 'pi pi-file',
    //     routerLink: 'pages/master/bizday',
    //     routerLinkActiveOptions: 'active',
    //   };
    //   master['items'].push(item);
    // }

    // 組織マスタ
    if (DEPARTMENT_TYPE.TYPE_OTHER == this.loginUser.department_type) {
      // 9:その他

      let item: object = {
        label: '組織',
        icon: 'pi pi-file',
        routerLink: 'pages/master/department',
        routerLinkActiveOptions: 'active',
      };
      master['items'].push(item);
    }

    // ユーザーマスタ
    if (DEPARTMENT_TYPE.TYPE_OTHER == this.loginUser.department_type) {
      // 9:その他

      let item: object = {
        label: 'ユーザ',
        icon: 'pi pi-file',
        routerLink: 'pages/master/user',
        routerLinkActiveOptions: 'active',
      };
      master['items'].push(item);
    }

    // お知らせマスタ
    if (DEPARTMENT_TYPE.TYPE_OTHER == this.loginUser.department_type) {
      // 9:その他

      let item: object = {
        label: 'お知らせ',
        icon: 'pi pi-file',
        routerLink: 'pages/master/information',
        routerLinkActiveOptions: 'active',
      };
      master['items'].push(item);
    }

    // 資料・リンク集
    {
      let item: object = {
        label: '資料・リンク集',
        icon: 'pi pi-list',
        routerLink: 'pages/link',
        routerLinkActiveOptions: 'active',
      };
      others['items'].push(item);
    }
  }
}
