import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/service/login.service';
import { DEPARTMENT_TYPE } from 'src/app/shared/constant/db-constant';
import { TITLE } from '../../../../../manager/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

/**
 * ヘッダー
 */
export class HeaderComponent implements OnInit {
  // ヘッダー判定フラグ(true:通常モード、false:メッセージモード)
  @Input() headerFlag: boolean;

  // タイトルヘッダーを設定
  title: string = TITLE.TITLE_HEADER;

  // カートボタン表示フラグ
  cartDisplayFlag: boolean = false;

  // メニュー情報
  menuitems: MenuItem[] = new Array();

  //ログイン情報（ユーザー名）
  user_name: string;

  constructor(private router: Router, private loginService: LoginService) { }

  ngOnInit() {
    // ログインユーザ情報取得処理
    this.loginService.getLoginUser().subscribe((response) => {
      // ユーザ情報の組織種別を判定
      if (
        2 == response.body[0].department_type ||
        9 == response.body[0].department_type
      ) {
        // 2:寄贈先 or 9:その他

        // カートボタンを表示
        this.cartDisplayFlag = true;
      }

      // メニュー情報設定
      this.menu(response.body[0].department_type);

      this.user_name = response.body[0].user_name;
    });
  }

  /**
   * メニュー情報設定
   * @param departmentType 組織種別
   */
  private menu(departmentType: string) {
    // 発注管理
    let order: object = {};
    order['label'] = '注文';
    order['items'] = [];
    this.menuitems.push(order);

    // マスタ
    let master: object = {};
    master['label'] = '管理';
    master['items'] = [];
    this.menuitems.push(master);

    // その他
    let others: object = {};
    others['label'] = 'その他';
    others['items'] = [];
    this.menuitems.push(others);

    // ログアウト
    let logout: object = {
      items: [
        {
          label: 'ログアウト',
          icon: 'pi pi-times-circle',
          command: (event) => {
            this.loginService.logout();
          },
        },
      ],
    };
    this.menuitems.push(logout);

    // 食品選択
    if (
      DEPARTMENT_TYPE.TYPE_DESTINATION == departmentType ||
      DEPARTMENT_TYPE.TYPE_OTHER == departmentType
    ) {
      // 2:寄贈先 or 9:その他
      {
        let item: object = {
          label: '食品選択',
          icon: 'pi pi-search',
          routerLink: 'pages/order/item-search',
          routerLinkActiveOptions: 'active',
        };
        order['items'].push(item);
      }
    }

    // 届先選択
    if (
      DEPARTMENT_TYPE.TYPE_DESTINATION == departmentType ||
      DEPARTMENT_TYPE.TYPE_OTHER == departmentType
    ) {
      // 2:寄贈先 or 9:その他
      {
        let item: object = {
          label: '届先選択',
          icon: 'pi pi-search',
          routerLink: 'pages/order/delivery-search',
          routerLinkActiveOptions: 'active',
        };
        order['items'].push(item);
      }
    }

    // 選択リスト
    if (
      DEPARTMENT_TYPE.TYPE_DESTINATION == departmentType ||
      DEPARTMENT_TYPE.TYPE_OTHER == departmentType
    ) {
      // 2:寄贈先 or 9:その他
      {
        let item: object = {
          label: '選択リスト',
          icon: 'pi pi-search',
          routerLink: 'pages/order/cart',
          routerLinkActiveOptions: 'active',
        };
        order['items'].push(item);
      }
    }

    // 注文履歴
    {
      let item: object = {
        label: '注文履歴',
        icon: 'pi pi-list',
        routerLink: 'pages/order/performance',
        routerLinkActiveOptions: 'active',
      };
      order['items'].push(item);
    }

    // 販売促進マスタ
    if (
      DEPARTMENT_TYPE.TYPE_FORMER == departmentType ||
      DEPARTMENT_TYPE.TYPE_OFFICE == departmentType ||
      DEPARTMENT_TYPE.TYPE_OTHER == departmentType
    ) {
      // 1:寄贈元の場合 or 3:配送事務局 or 9:その他

      let item: object = {
        label: '食品',
        icon: 'pi pi-file',
        routerLink: 'pages/master/item',
        routerLinkActiveOptions: 'active',
      };
      master['items'].push(item);
    }

    // 届先マスタ
    if (
      DEPARTMENT_TYPE.TYPE_DESTINATION == departmentType ||
      DEPARTMENT_TYPE.TYPE_OFFICE == departmentType ||
      DEPARTMENT_TYPE.TYPE_OTHER == departmentType
    ) {
      // 2:寄贈先 or 3:配送事務局 or 9:その他

      let item: object = {
        label: '届先',
        icon: 'pi pi-file',
        routerLink: 'pages/master/delivery',
        routerLinkActiveOptions: 'active',
      };
      master['items'].push(item);
    }

    // TODO:都庁案件で不要の為、コメントアウト
    // 営業日マスタ
    // if ('9' == departmentType) {
    //   // 9:その他

    //   let item: object = {
    //     label: '営業日マスタ',
    //     icon: 'pi pi-file',
    //     routerLink: 'pages/master/bizday',
    //     routerLinkActiveOptions: 'active',
    //   };
    //   master['items'].push(item);
    // }

    // 組織マスタ
    if (DEPARTMENT_TYPE.TYPE_OTHER == departmentType) {
      // 9:その他

      let item: object = {
        label: '組織',
        icon: 'pi pi-file',
        routerLink: 'pages/master/department',
        routerLinkActiveOptions: 'active',
      };
      master['items'].push(item);
    }

    // ユーザーマスタ
    if (DEPARTMENT_TYPE.TYPE_OTHER == departmentType) {
      // 9:その他

      let item: object = {
        label: 'ユーザ',
        icon: 'pi pi-file',
        routerLink: 'pages/master/user',
        routerLinkActiveOptions: 'active',
      };
      master['items'].push(item);
    }

    // お知らせマスタ
    if (DEPARTMENT_TYPE.TYPE_OTHER == departmentType) {
      // 9:その他

      let item: object = {
        label: 'お知らせ',
        icon: 'pi pi-file',
        routerLink: 'pages/master/information',
        routerLinkActiveOptions: 'active',
      };
      master['items'].push(item);
    }

    // 資料・リンク集
    {
      let item: object = {
        label: '資料・リンク集',
        icon: 'pi pi-list',
        routerLink: 'pages/link',
        routerLinkActiveOptions: 'active',
      };
      others['items'].push(item);
    }
  }

  /**
   * Top画面遷移
   */
  onHome() {
    this.router.navigate(['']);
  }

  /**
   * カート画面遷移
   */
  onCart() {
    this.router.navigate(['pages/order/cart']);
  }
}
