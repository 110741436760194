import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URL_VALIDATION_RESULT } from 'manager/http-constants';
import { map } from 'rxjs/operators';
import { CommonService } from '../service/common.service';

@Injectable({
  providedIn: 'root',
})
/**
 * 共通バリデーションチェック.サービス
 */
export class CommonValidatorService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * 入力項目値バリデーションチェック(単項目)
   * @param tblId テーブル名
   * @param colId カラム名
   * @param val 入力値
   * @returns JSONデータ
   */
  public getValidatorResult(
    tblId: string,
    colId: string,
    val: string
  ): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(
      API_URL_VALIDATION_RESULT,
      '?TableID=',
      tblId,
      '&ColumnID=',
      colId,
      '&Value=',
      encodeURI(val)
    );

    // 取得データの返却
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }
}
