<p-toolbar>
  <div class="p-toolbar-group-left">
    <!-- メニュー-->
    <!--メッセージモード以外の場合、表示-->
    <button #btn pButton type="button" icon="pi pi-bars" class="p-button-text p-button-secondary"
      (click)="menu.toggle($event)" *ngIf="headerFlag"></button>
    <p-menu #menu [model]="menuitems" [popup]="true"></p-menu>

    <!-- TOP画面-->
    <!--メッセージモード以外の場合、表示-->
    <button pButton type="button" icon="pi pi-home" (click)="onHome()" class="p-button-text p-button-secondary"
      *ngIf="headerFlag"></button>
  </div>

  <!-- システム名 -->
  <div class="p-toolbar-group-center">
    <p>{{ title }}</p>
  </div>

  <div class="p-toolbar-group-right">
    <button pButton class=" p-button-text p-button-secondary right" icon="pi pi-user" pTooltip="{{user_name}}"
      tooltipPosition="bottom" *ngIf="headerFlag"></button>
    <!-- カート画面 -->
    <!--ログインユーザの組織種別が 2:寄贈先 or 9:その他 の場合、表示-->
    <!--メッセージモード以外の場合、表示-->
    <button pButton type="button" icon="pi pi-shopping-cart" (click)="onCart()"
      class="p-button-text p-button-secondary button" *ngIf="headerFlag && cartDisplayFlag"></button>
  </div>
</p-toolbar>