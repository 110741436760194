<div class="message">
  <!-- メッセージエリア -->
  <div class="messageArea">
    <p [innerHTML]="message"></p>
  </div>

  <!-- ボタンエリア -->
  <div class="buttonArea">
    <button
      class="button"
      type="button"
      label="再ログイン"
      (click)="login()"
      pButton
    ></button>
  </div>
</div>
