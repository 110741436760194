import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['../order.component.scss', './cart.component.scss'],
  providers: [MessageService],
})

/**
 * 発注管理_選択リスト
 */
export class CartComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
