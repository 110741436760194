import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

/** モジュール */
// ルーティング
import { AppRoutingModule } from './app-routing.module';

// 共通モジュール
import { PagesModule } from './pages/pages.module';
import { SharedModule } from './shared/shared.module';

/** 定数 */
import { environment_auth0 } from 'manager/environment';

/** コンポーネント */
import { AppComponent } from './app.component';

/** サービス */
import {
  AuthInterceptor,
  ErrorInterceptor,
} from 'src/app/shared/service/interceptor.service';

/** Auth0 */
import { AuthModule, AuthHttpInterceptor } from '@auth0/auth0-angular';

/** ngx-translate */
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    PagesModule,
    SharedModule,
    AuthModule.forRoot({
      domain: environment_auth0.AUTH0_DOMAIN,
      clientId: environment_auth0.AUTH0_CLIENT_ID,
      audience: environment_auth0.AUDIENCE,
      redirectUri: `${window.location.origin}`,
      httpInterceptor: {
        allowedList: [
          {
            uri: environment_auth0.API_URL,
            tokenOptions: {
              audience: environment_auth0.AUDIENCE,
            },
          },
        ],
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
