import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { SESSION_KEY } from 'manager/environment';
import { CommonService } from 'src/app/shared/service/common.service';
import { LoginService } from 'src/app/shared/service/login.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})

/**
 * メッセージ画面
 */
export class MessageComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private loginService: LoginService,
    public auth: AuthService
  ) {}

  // メッセージ格納先
  message: string;

  ngOnInit(): void {
    // セッションに保存したメッセージコードからメッセージを取得
    this.message = this.commonService.msg(
      window.sessionStorage.getItem(SESSION_KEY.loginOutMessageCode)
    );

    // セッションに保存したメッセージコードを削除
    window.sessionStorage.removeItem(SESSION_KEY.loginOutMessageCode);
  }

  /**
   * ログインボタン
   */
  public login(): void {
    // ログイン処理を実施(ログイン後デフォルトルートに遷移)
    this.loginService.login();
  }
}
