<!-- 入力フォーム -->
<div class="generateInputForm" *ngIf="!confirmationFlag">
  <form [formGroup]="generateInputForm">
    <!-- 入力情報出力領域 -->
    <p-table [value]="generateInputList" styleClass="p-datatable-sm">
      <!-- 入力情報タイトル -->
      <ng-template pTemplate="header">
        <tr>
          <th colspan="2">入力</th>
        </tr>
      </ng-template>
      <!-- 入力情報出力箇所 -->
      <ng-template pTemplate="body" let-generateInputInformation>
        <tr>
          <!-- カラム名称 -->
          <td class="label-center">
            <label class="p-col-fixed">
              {{ generateInputInformation.column_name }}

              <!-- 項目定義マスタ.入力必須有無に'1'が存在する場合、必須を表示 -->
              <span
                *ngIf="generateInputInformation.column_input_require == '1'"
                class="p-tag p-tag-danger require"
              >
                必須
              </span>
            </label>
          </td>

          <!-- 入力項目 -->
          <td>
            <!-- 項目定義マスタ.memoに値が存在する場合に表示 -->
            <span
              *ngIf="generateInputInformation.column_memo != ''"
              class="p-error memo"
            >
              {{ generateInputInformation.column_memo }}
            </span>
            <!-- 入力項目タイプを判定 -->
            <!-- テキスト -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'text'"
            >
              <input
                [name]="generateInputInformation.column_id"
                type="text"
                [maxlength]="generateInputInformation.column_hsize"
                [formControlName]="generateInputInformation.column_id"
                pInputText
              />
            </div>

            <!-- 日付 -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'date'"
            >
              <p-calendar
                [name]="generateInputInformation.column_id"
                [showIcon]="true"
                [readonlyInput]="true"
                [showButtonBar]="true"
                dateFormat="yy/mm/dd"
                dataType="string"
                [formControlName]="generateInputInformation.column_id"
                translate
              >
              </p-calendar>
            </div>

            <!-- 数字 -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'number'"
            >
              <input
                [name]="generateInputInformation.column_id"
                type="number"
                [formControlName]="generateInputInformation.column_id"
                pInputText
              />
            </div>

            <!-- チェックボックス -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'checkbox'"
            >
              <div
                class="p-field-checkbox"
                *ngFor="
                  let code_list of generateInputInformation.column_code_list_multi
                "
              >
                <p-checkbox
                  [name]="generateInputInformation.column_id"
                  [label]="code_list.name"
                  [value]="code_list.value"
                  [formControl]="
                    generateInputForm.controls[
                      generateInputInformation.column_id
                    ]
                  "
                >
                </p-checkbox>
              </div>
            </div>

            <!-- ラジオボタン -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'radio'"
            >
              <div
                class="p-field-radiobutton"
                *ngFor="
                  let column of generateInputInformation.column_code_list_multi
                "
              >
                <p-radioButton
                  [name]="generateInputInformation.column_id"
                  [label]="column.name"
                  [value]="column.value"
                  [formControlName]="generateInputInformation.column_id"
                >
                </p-radioButton>
              </div>
            </div>

            <!-- シングルセレクト -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'single_select'"
            >
              <p-dropdown
                [name]="generateInputInformation.column_id"
                [options]="generateInputInformation.column_code_list_multi"
                placeholder="選択してください"
                optionLabel="name"
                [showClear]="true"
                [formControl]="
                  generateInputForm.controls[generateInputInformation.column_id]
                "
              >
              </p-dropdown>
            </div>

            <!-- マルチセレクト -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'multiple_select'"
            >
              <p-multiSelect
                [name]="generateInputInformation.column_id"
                [options]="generateInputInformation.column_code_list_multi"
                defaultLabel="選択してください"
                optionLabel="name"
                [formControl]="
                  generateInputForm.controls[generateInputInformation.column_id]
                "
              >
              </p-multiSelect>
            </div>

            <!-- テキストエリア -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'textarea'"
            >
              <textarea
                [name]="generateInputInformation.column_id"
                [maxlength]="generateInputInformation.column_hsize"
                rows="10"
                [formControlName]="generateInputInformation.column_id"
                pInputTextarea
              ></textarea>
            </div>

            <!-- パスワード -->
            <div
              class="ui-fluid"
              *ngIf="generateInputInformation.input_type == 'password'"
            >
              <input
                [name]="generateInputInformation.column_id"
                type="password"
                [maxlength]="generateInputInformation.column_hsize"
                [formControlName]="generateInputInformation.column_id"
                pInputText
              />
            </div>

            <!-- エラーメッセージ出力箇所 -->
            <app-message-container
              [control]="
                generateInputForm.get(generateInputInformation.column_id)
              "
            >
            </app-message-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </form>

  <!-- ボタンエリア -->
  <div class="buttonArea">
    <button
      class="button"
      type="submit"
      label="入力チェック"
      (click)="inputVerification()"
      [disabled]="!generateInputForm.valid"
      pButton
    ></button>
  </div>
</div>

<!-- 入力確認 -->
<div class="generateInputConfirmation" *ngIf="confirmationFlag">
  <!-- 確認情報出力領域 -->
  <p-table
    [value]="generateInputFormConfirmationList"
    styleClass="p-datatable-sm"
  >
    <!-- 確認情報タイトル -->
    <ng-template pTemplate="header">
      <tr>
        <th colspan="2">確認</th>
      </tr>
    </ng-template>
    <!-- 確認情報出力箇所 -->
    <ng-template pTemplate="body" let-generateInputFormConfirmation>
      <tr>
        <!-- カラム名称 -->
        <td class="label-center">
          <label class="p-col-fixed">
            {{ generateInputFormConfirmation._columnName }}
          </label>
        </td>
        <!-- 入力項目値 -->
        <td class="ui-fluid confirmationData">
          {{ generateInputFormConfirmation._columnData }}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <!-- ボタンエリア -->
  <div class="buttonArea">
    <button
      class="button"
      type="button"
      label="登録"
      (click)="returnGenerateInputForm()"
      [disabled]="insertFlag"
      pButton
    ></button>
    <button
      class="button"
      type="button"
      label="戻る"
      (click)="confirmationFlag = false"
      [disabled]="insertFlag"
      pButton
    ></button>
  </div>
</div>
