import { Injectable } from '@angular/core';
import {
  FormControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CONSTANT } from '../constant/constant';
import { INPUT_INFORMATIONS_API_CONSTANT } from '../constant/api-constant';
import { CommonValidatorService } from './common-validator.service';

@Injectable({
  providedIn: 'root',
})
export class CommonValidator {
  constructor(private commonValidatorService: CommonValidatorService) {}

  /**
   * 共通バリデーションチェック(カスタムバリデーター)
   * @param tblId テーブル名
   * @param colId カラム名
   * @param inputType 入力項目タイプ
   */
  public CommonValidator(
    tblId: string,
    colId: string,
    inputType: string
  ): AsyncValidatorFn {
    return (
      control: FormControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      // 入力項目値を取得
      const inputValue = control.value;

      // チェックAPIを呼び出し
      return this.commonValidatorService
        .getValidatorResult(tblId, colId, inputValue)
        .pipe(
          map((response) => {
            /* 入力文字列の置換 */
            // 入力タイプを判定
            if (
              INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE == inputType ||
              INPUT_INFORMATIONS_API_CONSTANT.NUMBER_TYPE == inputType ||
              INPUT_INFORMATIONS_API_CONSTANT.DATE_TYPE == inputType ||
              INPUT_INFORMATIONS_API_CONSTANT.TEXTAREA_TYPE == inputType
            ) {
              // 入力タイプが'テキスト'or'数値'or'日付'or'テキストエリア'の場合

              // 入力文字列と置換文字列が別々か否か
              if (inputValue != response.body[0].value) {
                // 入力文字列と置換文字列が別々の場合

                // 置換文字列が正常値か否か
                if ('null' == response.body[0].value) {
                  // 文字を空文字で置き換える
                  control.setValue(CONSTANT.EMPTY_STRING);
                } else {
                  // 文字を置き換える
                  control.setValue(response.body[0].value);
                }
              }
            }

            // 入力タイプを判定
            if (
              INPUT_INFORMATIONS_API_CONSTANT.SINGLE_SELECT_TYPE == inputType ||
              INPUT_INFORMATIONS_API_CONSTANT.MULTIPLE_SELECT_TYPE == inputType
            ) {
              // 入力タイプが'プルダウン'or'マルチプルダウン'の場合

              // 置換文字列が正常値か否か
              if ('null' == response.body[0].value) {
                // 文字を空文字で置き換える
                control.setValue(CONSTANT.EMPTY_STRING);
              }
            }

            // エラーメッセージ出力先格納変数
            let errors = {};

            // エラーメッセージが存在するか否か
            if (response.body[0].message) {
              // エラーメッセージが存在する場合

              // エラーメッセージ出力先格納変数にエラーメッセージを格納
              errors['message'] = response.body[0].message;
            }

            return errors;
          })
        );
    };
  }
}
