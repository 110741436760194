import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';
import { forkJoin, Observable, Subject } from 'rxjs';
import {
  CONSTANT,
  FILE_EXTENSION,
  FILE_CONSTANT,
} from 'src/app/shared/constant/constant';
import { HEADER_LIST_API_CONSTANT } from '../../constant/api-constant';
import { CommonService } from '../../service/common.service';
import { DbOperationService } from '../../service/db-operation.service';

@Injectable({
  providedIn: 'root',
})

/**
 * ファイル出力
 */
export class ExportFileService {
  constructor(
    private dbOperationService: DbOperationService,
    private commonService: CommonService
  ) {}

  /**
   * CSVファイル出力(テンプレートに紐づくデータでcsv出力)
   * @param tableName テーブル名
   * @param fileName ファイル名
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param searchConditions 検索条件入力値
   * @param createCode コード値作成フラグ
   * true → ヘッダーはカラム値、データ部はコード値で作成
   * false → ヘッダーは論理値、データ部はコード変換値で作成
   * @param returnFileInformation ファイル情報を返却フラグ
   * @returns ファイル情報オブジェクト
   * ※returnsはsubscribeで取得
   */
  public exportTemplateCsv(
    tableName: string,
    fileName: string,
    endPoint: string,
    templateId: number,
    searchConditions?: any,
    createCode?: boolean,
    returnFileInformation?: boolean
  ): Subject<fileInformation> {
    // CSVファイル出力(テンプレートに紐づくデータでcsv出力)の返却
    let returnExportTemplateCsv = new Subject<fileInformation>();

    /* CSV情報を取得 */
    // 非同期同時実行リスト
    /* API分割リクエストを生成 */
    this.dbOperationService
      .createForkJoinTask(
        tableName,
        endPoint,
        templateId,
        searchConditions,
        createCode
      )
      .subscribe((task) => {
        // API分割リクエストの配列数を保持
        const getDataTaskListLength = task.length;

        // API同時実行タスクに"CSVヘッダー情報取得"を追加
        task.push(
          /* CSVヘッダー情報取得 */
          this.dbOperationService.getHeaderList(templateId)
        );

        // 非同期同時実行
        forkJoin(task).subscribe((responseList) => {
          // API分割リクエストの結果を結合
          responseList = this.commonService.JoinSearchResponseList(
            responseList,
            getDataTaskListLength
          );

          // CSVファイル出力
          const fileInformation: fileInformation = this.exportCsv(
            fileName,
            responseList[1].body,
            // データ情報が存在するか否か
            this.commonService.checkNoneResponse(responseList[0])
              ? // データ情報が存在しない場合
                new Array()
              : // データ情報が存在する場合
                responseList[0].body,
            createCode,
            returnFileInformation
          );

          // ファイル情報を返却フラグがtrueの場合
          if (returnFileInformation) {
            // ファイル情報オブジェクトを返却
            returnExportTemplateCsv.next(fileInformation);
          }
        });
      });

    // ファイル情報オブジェクトを返却
    return returnExportTemplateCsv;
  }

  /**
   * CSVファイル出力
   * @param fileName ファイル名
   * @param header ヘッダー情報
   * @param data データ情報
   * @param createCode コード値作成フラグ (true:ヘッダーをカラム値、false:ヘッダーを論理値)
   * @param returnFileInformation ファイル情報を返却フラグ
   */
  public exportCsv(
    fileName: string,
    header: object[],
    data: object[],
    createCode?: boolean,
    returnFileInformation?: boolean
  ): fileInformation {
    // ファイル名とヘッダー情報の必須判定
    if (!fileName || !header) {
      // ファイル名かヘッダー情報が空かnullの場合

      return;
    }

    // csv内容格納先
    let record;

    /* ヘッダー情報を生成 */
    record =
      this.commonService
        .createArrayGetArrayObject(
          header,
          // コード値作成フラグがtrueか否か
          createCode
            ? //  コード値作成フラグがtrueの場合、カラム名で作成
              HEADER_LIST_API_CONSTANT.FIELD
            : // コード値作成フラグがfalseの場合、論理名で作成
              HEADER_LIST_API_CONSTANT.HEADER
        )
        .join(CONSTANT.COMMA) + FILE_CONSTANT.LINE_CODE;

    /* データ情報を生成 */
    data.forEach((dataObject) => {
      // データ情報の行の格納先
      let dataLine = CONSTANT.EMPTY_STRING;

      // ヘッダー情報分ループ
      header.forEach((headerData) => {
        // ヘッダー情報のデータ情報が存在するか否か
        if (
          this.commonService.ifZeroPermission(
            dataObject[headerData[HEADER_LIST_API_CONSTANT.FIELD]]
          )
        ) {
          // データ情報が存在する場合

          // データ情報格納先にヘッダー情報のデータ情報を格納
          dataLine +=
            CONSTANT.DOUBLE_QUOTATION +
            dataObject[headerData[HEADER_LIST_API_CONSTANT.FIELD]] +
            CONSTANT.DOUBLE_QUOTATION +
            CONSTANT.COMMA;
        } else {
          // データ情報が存在しない場合

          // データ情報格納先に空文字
          dataLine +=
            CONSTANT.DOUBLE_QUOTATION +
            CONSTANT.DOUBLE_QUOTATION +
            CONSTANT.COMMA;
        }
      });

      // データ情報の行末尾のカンマを削除
      dataLine = dataLine.slice(0, -1);

      // csv内容格納先にデータ情報の行と改行を追加
      record += dataLine + FILE_CONSTANT.LINE_CODE;
    });

    // ファイル情報を生成
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, record], { type: 'text/csv' });

    // ファイル情報を返却フラグがtrueの場合
    if (returnFileInformation) {
      // ファイル情報オブジェクトを返却
      return new fileInformation({
        fileName: fileName + FILE_EXTENSION.CSV,
        blob: blob,
      });
    }

    // ファイルダウンロード
    this.fileDownload(fileName + FILE_EXTENSION.CSV, blob);
  }

  /**
   * ZIPファイルダウンロード
   * @param fileName ZIPファイル名
   * @param fileInformation ZIP化を行うファイル情報(BLOB形式)
   */
  public zipFileDownload(
    fileName: string,
    ...fileInformationList: fileInformation[]
  ) {
    // JSZipオブジェクトを作成
    var zip = new JSZip();

    // ZIP化を行うファイル情報(BLOB形式)リスト分ループ
    for (const fileInformation of fileInformationList) {
      // JSZipオブジェクトにファイル名、ファイル情報(BLOB形式)を格納
      zip.file(fileInformation.fileName, fileInformation.blob);
    }

    // JSZipオブジェクトを用いてファイルをZIP化
    zip.generateAsync({ type: 'blob' }).then((zipInformation) => {
      // ファイルダウンロード
      this.fileDownload(fileName + FILE_EXTENSION.ZIP, zipInformation);
    });
  }

  /**
   * ファイルダウンロード
   * @param fileName ファイル名
   * @param blob ファイル情報(BLOB形式)
   */
  private fileDownload(fileName: string, blob: Blob) {
    // TODO IE対応
    // ブラウザ情報を取得
    const userAgent = window.navigator.userAgent.toLowerCase();

    // ブラウザを判定
    if (userAgent.indexOf('trident') == -1) {
      // IE以外の場合

      // ファイル情報(BLOB形式)をURL化
      const url = window.URL.createObjectURL(blob);

      // ファイルダウンロード
      let link = document.createElement('a');
      document.body.appendChild(link);
      link.setAttribute('style', 'display: none');

      link.href = url;
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(url);
    } else {
      // IEの場合

      // 特殊処理でファイルを出力
      // IEでは"blob"出力に対応していない為、特殊処理を実施
      window.navigator.msSaveBlob(blob, fileName);
    }
  }
}

/** ファイル情報オブジェクト */
export class fileInformation {
  // ファイル名(拡張子含める)
  private _fileName: string;

  // ファイル情報(BLOB形式)
  private _blob: Blob;

  constructor(init?: Partial<fileInformation>) {
    Object.assign(this, init);
  }

  set fileName(fileName: string) {
    this._fileName = fileName;
  }

  get fileName(): string {
    return this._fileName;
  }

  set blob(blob: Blob) {
    this._blob = blob;
  }

  get blob(): Blob {
    return this._blob;
  }
}
